import getBatteryStatus from "../../../Utils/getBatteryStatus";

export const batteryPercentageFilters = [
  {
    id: 1,
    value: "0-10%",
  },
  {
    id: 2,
    value: "11-20%",
  },
  {
    id: 3,
    value: "21-30%",
  },
  {
    id: 4,
    value: "31-40%",
  },
  {
    id: 5,
    value: "41-50%",
  },
  {
    id: 6,
    value: "51-60%",
  },
  {
    id: 7,
    value: "61-70%",
  },
  {
    id: 8,
    value: "71-80%",
  },
  {
    id: 9,
    value: "81-90%",
  },
  {
    id: 10,
    value: "91-100%",
  },
];

export const statusFilters = [
  {
    id: 1,
    value: getBatteryStatus(1),
  },
  {
    id: 2,
    value: getBatteryStatus(2),
  },
  {
    id: 3,
    value: getBatteryStatus(3),
  },
  {
    id: 4,
    value: getBatteryStatus(4),
  },
];

export const BATTERY_STATUS = {
  ON_A_TRIP: 1,
  DISPATCHED: 2,
  HUB_STATION: 3,
  SERVICE: 4,
};
