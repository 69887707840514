/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { updateTenants } from "../../../Redux/Slices/tenants.slice";

const LabelAndInput = ({
  label,
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
}) => {
  return (
    <div className="">
      <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
        {label}
      </p>
      <CustomInputWithController
        intent="createUser"
        control={control}
        formState={formState}
        focus={focus}
        setFocus={(e) => setFocus(e)}
        name={name}
        placeholder={placeholder}
        rules={rules}
      />
    </div>
  );
};

const CreateTenant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      name: "",
      email: "",
      contactNo: "",
    },
  });

  const [focus, setFocus] = useState({
    name: false,
    email: false,
    contactNo: false,
  });

  const rules = {
    required: "Required.",
  };

  const rulesForEmail = {
    required: "Required.",
    pattern: {
      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/,
      message: "Enter a vaild email",
    },
  };

  const rulesForContact = {
    required: "Required.",
    minLength: {
      value: 10,
      message: "Enter a vaild contact no",
    },
  };

  const onCancel = () => navigate(-1);

  const onCreate = (e) => {
    console.log(e, "check on create");

    dispatch(
      updateTenants({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: "Tenant Created & invite sent",
            description: `${e.name} was created and invited successfully.`,
          },
        },
      }),
    );

    navigate(-1);
  };

  return (
    <CustomLayout pageName="Settings" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            Create a new tenant
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              size="filter"
              width="generateUser"
              type="submit"
              onClick={handleSubmit(onCreate)}
            >
              Create & Invite
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-[calc(100vh-88px-72px)] bg-background-white p-6 flex flex-col gap-y-6 overflow-y-scroll">
        <LabelAndInput
          label="Organisation Name"
          control={control}
          formState={formState}
          name="name"
          placeholder="Enter Name"
          rules={rules}
          focus={focus.name}
          setFocus={(state) => setFocus({ ...focus, name: state })}
        />

        <LabelAndInput
          label="Email Address"
          control={control}
          formState={formState}
          name="email"
          placeholder="Enter Email"
          rules={rulesForEmail}
          focus={focus.email}
          setFocus={(state) => setFocus({ ...focus, email: state })}
        />

        <LabelAndInput
          label="Contact Number"
          control={control}
          formState={formState}
          name="contactNo"
          placeholder="Enter Number"
          rules={rulesForContact}
          focus={focus.contactNo}
          setFocus={(state) => setFocus({ ...focus, contactNo: state })}
        />
      </div>
    </CustomLayout>
  );
};

export default CreateTenant;
