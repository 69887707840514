/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import { updateUsers } from "../../../Redux/Slices/users.slice";
import AlertIcon from "../../../Common/Svgs/AlertIcon";
import CalendarIcon from "../../../Common/Svgs/CalendarIcon";
import BlockedEyeIcon from "../../../Common/Svgs/BlockedEyeIcon";
import { client } from "../../../Utils/axiosClient";
import { editDriverV3 } from "../../../Redux/APIs/usersAPI";
import { LOCATION_TYPE } from "../../Location/Data";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";

const LabelAndInput = ({
  label,
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
  inputType = "text",
  suffix = null,
}) => {
  return (
    <div className="">
      <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
        {label}
      </p>
      <CustomInputWithController
        intent="createUser"
        control={control}
        formState={formState}
        focus={focus}
        setFocus={(e) => setFocus(e)}
        name={name}
        placeholder={placeholder}
        rules={rules}
        inputType={inputType}
        suffix={suffix}
      />
    </div>
  );
};

const AddDriver = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentDriver } = useSelector((state) => state.users);

  const { allLocations } = useSelector((state) => state.locations);

  const hubStations = allLocations
    ?.filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const {
    handleSubmit,
    control,
    formState,
    reset,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      firstName: id ? currentDriver.firstName : "",
      lastName: id ? currentDriver.lastName : "",
      licenseId: id ? currentDriver.licenseId : "",
      licenseValidity: id
        ? dayjs().isValid(currentDriver.licenseValidity)
          ? dayjs(currentDriver.licenseValidity)
          : null
        : "",
      contactNumber: id ? currentDriver.contactNo : "",
      userId: id ? currentDriver?.appUserId : "",
      password: "",
      updatePassword: "",
      hubId: id ? currentDriver?.hubid : null,
      hubName: id ? currentDriver?.hubName : "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const [focus, setFocus] = useState({
    firstName: false,
    lastName: false,
    licenseId: false,
    contactNumber: false,
    userId: false,
    password: false,
    updatePassword: false,
  });

  const licenseValidityError = formState?.errors?.licenseValidity?.message;
  const hubNameError = formState?.errors?.hubName?.message;

  const rules = {
    required: "Required.",
  };

  const onCancel = () => {
    dispatch(
      updateUsers({
        key: "selectedTab",
        value: 1,
      }),
    );
    navigate("/users");
  };

  const onAdd = async (e) => {
    const payload = e;

    if (id) {
      payload.driverId = id;
      await dispatch(editDriverV3(payload));

      reset();
      dispatch(
        updateUsers({
          key: "selectedTab",
          value: 1,
        }),
      );
      navigate("/users");
    } else {
      try {
        const response = await client.post(
          `${process.env.REACT_APP_API_URL}/drivers/add-driver`,
          payload,
        );

        if (response.status === 200) {
          reset();
          dispatch(
            updateUsers({
              key: "selectedTab",
              value: 1,
            }),
          );
          navigate("/users");
        }
      } catch (error) {
        setError("userId", {
          type: "server",
          message: error.response.data.message,
        });
      }
    }
  };

  return (
    <CustomLayout pageName="Users" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            {id ? "Edit Driver" : "Add a new driver"}
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              size="filter"
              width="generateUser"
              type="submit"
              onClick={handleSubmit(onAdd)}
            >
              {id ? "Edit" : "Add"} Driver
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-[calc(100vh-88px-72px)] bg-background-white p-6 flex flex-col gap-y-6 overflow-y-scroll">
        <LabelAndInput
          label="First Name"
          control={control}
          formState={formState}
          name="firstName"
          placeholder="Enter Name"
          rules={rules}
          focus={focus.firstName}
          setFocus={(state) => setFocus({ ...focus, firstName: state })}
        />
        <LabelAndInput
          label="Last Name"
          control={control}
          formState={formState}
          name="lastName"
          placeholder="Enter Name"
          rules={rules}
          focus={focus.lastName}
          setFocus={(state) => setFocus({ ...focus, lastName: state })}
        />
        <LabelAndInput
          label="License Id"
          control={control}
          formState={formState}
          name="licenseId"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.licenseId}
          setFocus={(state) => setFocus({ ...focus, licenseId: state })}
        />

        <div>
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            License Validity
          </p>

          <Controller
            name="licenseValidity"
            rules={rules}
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                className={`font-aileron text-dark-gray font-semibold text-xs h-10 w-[367px] ${
                  licenseValidityError &&
                  "border border-alert-red hover:border-alert-red"
                }`}
                placeholder="Select Date"
                format="MMM DD, YYYY"
                disabledDate={(current) =>
                  current.isBefore(dayjs().subtract(1, "day"))
                }
                suffixIcon={
                  licenseValidityError ? (
                    <AlertIcon className="text-alert-red" />
                  ) : (
                    <CalendarIcon />
                  )
                }
                allowClear={false}
              />
            )}
          />
          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 mt-2 ${
              licenseValidityError ? "text-alert-red visible" : "invisible"
            }`}
          >
            {licenseValidityError}
          </p>
        </div>

        <LabelAndInput
          label="Contact Number"
          control={control}
          formState={formState}
          name="contactNumber"
          placeholder="Enter Number"
          rules={rules}
          focus={focus.contactNumber}
          setFocus={(state) => setFocus({ ...focus, contactNumber: state })}
        />

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Hub Station
          </p>

          <Controller
            name="hubName"
            control={control}
            rules={rules}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                enableSearch
                searchPlaceholder="Search Hub Station"
                placeholder="Assign Hub Station"
                className="max-h-[300px] overflow-y-scroll"
                formState={formState}
                options={hubStations}
                onSelect={(data) => {
                  setValue("hubName", data.value);
                  setValue("hubId", data.id);
                  clearErrors("hubName");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 mt-2 ${
              hubNameError ? "text-alert-red visible" : "invisible"
            }`}
          >
            {hubNameError}
          </p>
        </div>

        {id ? (
          <div>
            <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
              User ID
            </p>
            <div className="h-10 w-[367px] bg-background border borer-light-grey rounded-md py-2 px-4 mb-6">
              <p>{formState?.defaultValues?.userId}</p>
            </div>

            <LabelAndInput
              label="Update Password"
              control={control}
              formState={formState}
              name="updatePassword"
              placeholder="Enter new password"
              // rules={rules}
              focus={focus.updatePassword}
              setFocus={(state) =>
                setFocus({ ...focus, updatePassword: state })
              }
              inputType={showPassword ? "text" : "password"}
              suffix={
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <img src="/images/eye.png" className="w-5 h-5" alt="" />
                  ) : (
                    <BlockedEyeIcon className="text-black" />
                  )}
                </button>
              }
            />
          </div>
        ) : (
          <>
            <p className="font-poppins text-xs font-medium pb-2 border-b border-light-grey w-[367px] capitalize">
              Set login credentials For the driver’s mobile app
            </p>

            <LabelAndInput
              label="User ID"
              control={control}
              formState={formState}
              name="userId"
              placeholder="Enter ID"
              rules={rules}
              focus={focus.userId}
              setFocus={(state) => setFocus({ ...focus, userId: state })}
            />

            <LabelAndInput
              label="Password"
              inputType="password"
              control={control}
              formState={formState}
              name="password"
              placeholder="Enter Password"
              rules={rules}
              focus={focus.password}
              setFocus={(state) => setFocus({ ...focus, password: state })}
            />
          </>
        )}
      </div>
    </CustomLayout>
  );
};

export default AddDriver;
