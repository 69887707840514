/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import ChargerHeader from "./ChargerHeader";
import CustomTable from "../../Core/Components/CustomTable";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import ChargerIcon from "../../Common/Svgs/ChargerIcon";
import { chargerColumns, chargerData } from "./Data/listingsData";
import ToastMessageModal from "./ToastMessageModal";
import { updateCharger } from "../../Redux/Slices/chargers.slice";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";
import DeleteChargerModal from "./DeleteChargerModal";

const ChargerList = () => {
  const dispatch = useDispatch();

  const { showToastMessage, deleteChargerModal } = useSelector(
    (state) => state.chargers,
  );

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
    type: [],
  });

  const LOCAL_DEFAULT_LIMIT = 10;

  //   const debounceSearch = useDebounce(search, 500);

  // close the toast message after 5 seconds
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateCharger({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <CustomLayout pageName="Chargers">
      <div>
        <ChargerHeader
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
        />

        <CustomTable
          scrollX={0}
          scrollY="518px"
          columns={chargerColumns}
          data={chargerData}
          emptyState={<ChargerIcon size={16} className="text-dark-grey-text" />}
          rowClassName="group"
          pagination={{
            pageSize: LOCAL_DEFAULT_LIMIT,
            total: chargerData.length,
            showSizeChanger: false,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            itemRender: (current, type, originalElement) => {
              const lastPage = chargerData.length / current;

              if (type === "prev" && current === 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "prev" && current > 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next" && lastPage <= 10) {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{ pointerEvents: "none" }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next") {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              return originalElement;
            },
          }}
        />
      </div>

      <DeleteChargerModal
        visible={deleteChargerModal?.visible}
        onCancel={() =>
          dispatch(
            updateCharger({
              key: "deleteChargerModal",
              value: {
                visible: false,
                charger: {},
              },
            }),
          )
        }
      />

      <ToastMessageModal
        visible={showToastMessage.visible}
        onCancel={() =>
          dispatch(
            updateCharger({
              key: "showToastMessage",
              value: { visible: false, data: {} },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </CustomLayout>
  );
};

export default ChargerList;
