/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import WeatherInformation from "../../Modules/Home/WeatherInformation";
import NotificationPopup from "./Notifications/NotificationPopup";

const HeaderName = ({ pageName = "Home", nestedName }) => {
  const navigate = useNavigate();
  const { userDetails } = useSelector((state) => state.loggedInUser);
  const [greeting, setGreeting] = useState("Good Morning!");

  const date = new Date();
  const hours = date.getHours();

  const getGreetingText = () => {
    let greetingText;
    if (hours < 12) {
      greetingText = `Good Morning, ${userDetails.firstName}!`;
    }
    if (hours >= 12 && hours <= 16) {
      greetingText = `Good Afternoon, ${userDetails.firstName}!`;
    }
    if (hours >= 16) {
      greetingText = `Good Evening, ${userDetails.firstName}!`;
    }

    setGreeting(greetingText);
  };

  useEffect(() => {
    getGreetingText();
  }, []);

  return (
    <div>
      {nestedName ? (
        <div className="flex items-center justify-start text-2xl font-medium leading-9 cursor-pointer text-dark-gray font-poppins gap-x-3">
          <div className="capitalize" onClick={() => navigate(`/${pageName}`)}>
            {pageName}
          </div>
          <span className="flex items-center justify-start text-2xl font-medium leading-9 text-black font-poppins gap-x-3">
            <img src="/assets/icons/light-grey-chevron.svg" alt="" />
            {nestedName}
          </span>
        </div>
      ) : (
        <div className="flex items-center justify-start text-2xl font-semibold leading-9 text-black font-poppins">
          {pageName === "Home" ? `${greeting}` : pageName}
        </div>
      )}
    </div>
  );
};

const Header = ({ pageName = "Home", nestedName }) => {
  return (
    <div className="w-full border-b bg-background-white h-18 min-h-18 max-h-18 border-light-grey">
      <div className="flex items-center justify-between px-6 h-18">
        <HeaderName pageName={pageName} nestedName={nestedName} />
        <div className="flex items-center gap-x-6">
          <NotificationPopup />
          <div className="flex flex-col items-end">
            <div className="text-base font-medium leading-6 text-black font-poppins">
              {dayjs().format("ddd, MMM DD, YYYY")}
            </div>
            <WeatherInformation />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
