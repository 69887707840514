import React from "react";
import { useNavigate } from "react-router-dom";

const ItemBody2 = ({ notification }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/assign-batteries/${notification?.metaData?.hubId}`);
  };

  return (
    <div>
      <p className="font-poppins text-xs font-light text-black">
        Please assign batteries to trip id{" "}
        <span className="font-medium">{notification?.metaData?.tripId}</span>{" "}
        starting at{" "}
        <span className="font-medium">
          {notification?.metaData?.startTime}{" "}
        </span>
        from {notification?.metaData?.hubName}
      </p>

      <div className="mt-1 w-full flex items-center gap-x-2">
        <button
          type="button"
          className="bg-primary hover:bg-primary-50 py-2 px-4 rounded-md w-full"
          onClick={handleClick}
        >
          <p className="font-poppins text-xs font-medium text-background-white text-center">
            Assign Batteries
          </p>
        </button>
      </div>
    </div>
  );
};

export default ItemBody2;
