/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../Core/Components/CustomTable";
import CustomLayout from "../../Core/Layout/CustomLayout";
import { serviceColumns } from "./Data/ServiceListData";
import { getServicesV3 } from "../../Redux/APIs/servicesAPI";
import ServiceIcon from "../../Common/Svgs/ServiceIcon";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import ServiceListHeader from "./phase3/ServiceListHeader";
import ToastMessageModal from "./phase3/ToastMessageModal";
import { updateServices } from "../../Redux/Slices/services.slice";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";
import useDebounce from "../../Core/hooks/useDebounce";

const LOCAL_DEFAULT_LIMIT = 10;

function ServiceList() {
  const dispatch = useDispatch();

  const { serviceListV3, showToastMessage } = useSelector(
    (state) => state.services,
  );

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const hasThreeDotsPermission = usePermission([
    PERMISSIONS.SERVICE_EDIT,
    PERMISSIONS.SERVICE_COMPLETE,
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
    type: [],
    severity: [],
    dateRangeStart: "",
    dateRangeEnd: "",
    addedBy: [],
  });

  const debounceSearch = useDebounce(search, 500);

  // fetch all services (pagination based) along with applied filters
  useEffect(() => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.hub.length) filterObj.hub = filters.hub;
    if (filters.type.length) filterObj.type = filters.type;
    if (filters.severity.length) filterObj.severity = filters.severity;
    if (filters.dateRangeStart && filters.dateRangeEnd) {
      filterObj.dateRangeStart = filters.dateRangeStart;
      filterObj.dateRangeEnd = filters.dateRangeEnd;
    }
    if (filters.addedBy.length) {
      filterObj.addedBy = filters.addedBy.map((user) => user.id);
    }

    dispatch(getServicesV3(filterObj));
  }, [dispatch, currentPage, debounceSearch, filters]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <CustomLayout pageName="Service">
      <div>
        <ServiceListHeader
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
        />

        <CustomTable
          scrollX={0}
          scrollY="518px"
          columns={serviceColumns}
          data={serviceListV3.result}
          emptyState={
            <ServiceIcon className="w-4 h-4 fill-none stroke-dark-grey-text" />
          }
          rowClassName={(record) => {
            if (record.isCompleted) {
              return "group cursor-pointer";
            }

            if (hasThreeDotsPermission) {
              return "group cursor-pointer";
            }

            return "group";
          }}
          pagination={{
            pageSize: LOCAL_DEFAULT_LIMIT,
            total: serviceListV3.totalRecords,
            showSizeChanger: false,
            onChange: (page) => {
              setCurrentPage(page);
            },
            itemRender: (current, type, originalElement) => {
              const lastPage = serviceListV3.totalRecords / current;

              if (type === "prev" && current === 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "prev" && current > 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next" && lastPage <= 10) {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{ pointerEvents: "none" }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next") {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              return originalElement;
            },
          }}
        />
      </div>

      <ToastMessageModal
        visible={showToastMessage.visible}
        onCancel={() =>
          dispatch(
            updateServices({
              key: "showToastMessage",
              value: {
                visible: false,
                data: {},
              },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </CustomLayout>
  );
}
export default ServiceList;
