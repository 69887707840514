import React from "react";
import dayjs from "dayjs";
import AcceptBatteryRequestIcon from "../../../Common/Svgs/AcceptBatteryRequestIcon";
import UserIcon from "../../../Common/Svgs/UserIcon";
import { AlertTriangleIcon } from "../../../Common/Svgs/AlertIcon";
import ItemBody1 from "./ItemBody1";
import ItemBody2 from "./ItemBody2";
import ItemBody3 from "./ItemBody3";
import ItemBody4 from "./ItemBody4";
import ItemBody5 from "./ItemBody5";
import ItemBody6 from "./ItemBody6";
import ItemBody7 from "./ItemBody7";
import ItemBody8 from "./ItemBody8";

const NotificationItem = ({ notification, isLast }) => {
  const getIcon = () => {
    switch (notification.type) {
      case 1:
        return <AlertTriangleIcon size={24} className="stroke-alert-red" />;
      case 2:
        return <AlertTriangleIcon size={24} className="stroke-warning" />;
      case 3:
        return <UserIcon size={24} className="stroke-warning fill-none" />;
      // same icon for type 4,5,6,7,8
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        return <AcceptBatteryRequestIcon className="text-yellow-dark" />;
      default:
        return <div />;
    }
  };

  const getBody = () => {
    switch (notification.type) {
      case 1:
        return <ItemBody1 notification={notification} />;
      case 2:
        return <ItemBody2 notification={notification} />;
      case 3:
        return <ItemBody3 notification={notification} />;
      case 4:
        return <ItemBody4 notification={notification} />;
      case 5:
        return <ItemBody5 notification={notification} />;
      case 6:
        return <ItemBody6 notification={notification} />;
      case 7:
        return <ItemBody7 notification={notification} />;
      case 8:
        return <ItemBody8 notification={notification} />;
      default:
        return <div />;
    }
  };

  return (
    <div
      className={`py-4 px-6 hover:bg-background ${
        isLast ? "rounded-b-xl" : "border-b border-light-grey"
      }`}
    >
      <div className="flex items-center gap-x-2">
        {getIcon()}

        <p className="font-aileron text-10 font-normal text-dark-gray">
          {dayjs(notification?.submittedAt).format("hh:mm a")}
        </p>
      </div>

      <div className="mt-1 ml-8">{getBody()}</div>
    </div>
  );
};

export default NotificationItem;
