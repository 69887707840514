/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usersColumns } from "../Data/UsersListData";
import CustomTable from "../../../Core/Components/CustomTable";
import UserIcon from "../../../Common/Svgs/UserIcon";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { getDriversV3 } from "../../../Redux/APIs/usersAPI";
import { getUserByEmail } from "../../../Redux/APIs/systemUsersAPI";
import DriverHeader from "./DriverHeader";
import DeleteDriverModal from "./DeleteDriverModal";
import { updateUsers } from "../../../Redux/Slices/users.slice";
import useDebounce from "../../../Core/hooks/useDebounce";
import ViewDetailsDrawer from "../ViewDetailsDrawer";

const LOCAL_DEFAULT_LIMIT = 10;

const DriversList = () => {
  const dispatch = useDispatch();

  const { driversListV3, deleteDriverModal, tripDetailsDrawer } = useSelector(
    (state) => state.users,
  );

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
    currentTrike: [],
    driverDateRangeStart: "",
    driverDateRangeEnd: "",
    addedBy: [],
  });

  const debounceSearch = useDebounce(search, 500);

  // fetch drivers (pagination based) along with the filters
  useEffect(() => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;

    if (filters.hub.length) {
      filterObj.hub = filters.hub;
    }

    if (filters.currentTrike.length) {
      filterObj.currentTrike = filters.currentTrike;
    }

    if (filters.addedBy.length) {
      filterObj.addedBy = filters.addedBy.map((user) => user.id);
    }

    if (
      filters.driverDateRangeStart !== "" &&
      filters.driverDateRangeEnd !== ""
    ) {
      filterObj.dateRangeStart = filters.driverDateRangeStart;
      filterObj.dateRangeEnd = filters.driverDateRangeEnd;
    }

    dispatch(getDriversV3(filterObj));
  }, [dispatch, currentPage, filters, debounceSearch]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <div>
      <DriverHeader
        search={search}
        setSearch={setSearch}
        filters={filters}
        setFilters={setFilters}
        setCurrentPage={setCurrentPage}
      />

      <CustomTable
        scrollX={0}
        scrollY="446px"
        columns={usersColumns}
        data={driversListV3.result}
        emptyState={
          <UserIcon className="w-4 h-4 fill-none stroke-dark-grey-text" />
        }
        rowClassName="group"
        pagination={{
          pageSize: LOCAL_DEFAULT_LIMIT,
          total: driversListV3.totalRecords,
          showSizeChanger: false,
          onChange: (page) => {
            setCurrentPage(page);
          },
          current: currentPage,
          itemRender: (current, type, originalElement) => {
            const lastPage = driversListV3.totalRecords / current;

            if (type === "prev" && current === 0) {
              return (
                <button
                  type="button"
                  className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "prev" && current > 0) {
              return (
                <button
                  type="button"
                  className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "next" && lastPage <= 10) {
              return (
                <button
                  type="button"
                  className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  style={{ pointerEvents: "none" }}
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "next") {
              return (
                <button
                  type="button"
                  className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            return originalElement;
          },
        }}
      />

      <div>
        <ViewDetailsDrawer
          visible={tripDetailsDrawer.visible}
          onCancel={() =>
            dispatch(
              updateUsers({
                key: "tripDetailsDrawer",
                value: {
                  visible: false,
                  driverName: "",
                },
              }),
            )
          }
        />
      </div>

      <DeleteDriverModal
        visible={deleteDriverModal.visible}
        onCancel={() =>
          dispatch(
            updateUsers({
              key: "deleteDriverModal",
              value: {
                visible: false,
                id: 0,
              },
            }),
          )
        }
        driversCurrentPage={currentPage}
      />
    </div>
  );
};

export default DriversList;
