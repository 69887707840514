import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  tenantList: [],
  currentCharger: {},
  deleteChargerModal: {
    visible: false,
    charger: {},
  },
  showToastMessage: {
    visible: false,
    data: {},
  },

  threeDotsPopoverId: 0,
};

export const chargersSlice = createSlice({
  name: "chargers",
  initialState,
  reducers: {
    updateCharger: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { updateCharger } = chargersSlice.actions;

export default chargersSlice.reducer;
