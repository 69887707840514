/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../Core/Components/CustomTable";
import CustomLayout from "../../Core/Layout/CustomLayout";
import { locationColumns } from "./Data/LocationListData";
import {
  getAllLocationsV3,
  getLocationsV3,
} from "../../Redux/APIs/locationsAPI";
import LocationsModuleIcon from "../../Common/Svgs/LocationsModuleIcon";
import LocationListHeader from "./phase3/LocationListHeader";
import ChevronIcon from "../../Common/Svgs/ChevronIcon";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import { getUserByEmail } from "../../Redux/APIs/systemUsersAPI";
import useDebounce from "../../Core/hooks/useDebounce";

function LocationList() {
  const dispatch = useDispatch();

  const { locationsList } = useSelector((state) => state.locations);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
    type: [],
  });

  const LOCAL_DEFAULT_LIMIT = 10;

  const debounceSearch = useDebounce(search, 500);

  // check if three dots permission exist (cursor pointer scenario)
  const hasThreeDotsPermission = usePermission([
    PERMISSIONS.LOCATION_EDIT,
    PERMISSIONS.LOCATION_DELETE,
  ]);

  // fetch locations (with filters)
  useEffect(() => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;
    if (filters.hub.length) filterObj.hubs = filters.hub;
    if (filters.type.length) filterObj.type = filters.type;

    dispatch(getLocationsV3(filterObj));
  }, [dispatch, currentPage, filters, debounceSearch]);

  // fetch all the locations (without pagination)
  useEffect(() => {
    dispatch(getAllLocationsV3());
  }, []);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <CustomLayout pageName="Locations">
      <div>
        <LocationListHeader
          search={search}
          setSearch={setSearch}
          filters={filters}
          setFilters={setFilters}
          setCurrentPage={setCurrentPage}
        />

        <CustomTable
          scrollX={0}
          scrollY="518px"
          columns={locationColumns}
          data={locationsList.result}
          emptyState={
            <LocationsModuleIcon size={16} className="text-dark-grey-text" />
          }
          rowClassName={
            hasThreeDotsPermission ? "group cursor-pointer" : "group"
          }
          pagination={{
            pageSize: LOCAL_DEFAULT_LIMIT,
            total: locationsList.totalRecords,
            showSizeChanger: false,
            onChange: (page) => {
              setCurrentPage(page);
            },
            current: currentPage,
            itemRender: (current, type, originalElement) => {
              const lastPage = locationsList.totalRecords / current;

              if (type === "prev" && current === 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "prev" && current > 0) {
                return (
                  <button
                    type="button"
                    className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next" && lastPage <= 10) {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    style={{ pointerEvents: "none" }}
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              if (type === "next") {
                return (
                  <button
                    type="button"
                    className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  >
                    <ChevronIcon className="stroke-dark-gray" />
                  </button>
                );
              }

              return originalElement;
            },
          }}
        />
      </div>
    </CustomLayout>
  );
}
export default LocationList;
