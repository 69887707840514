/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import axios from "axios";
import { Checkbox, Popover } from "antd";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import CustomInput from "../../../Core/Components/CustomInput";
import { LOCATION_TYPE } from "../../Location/Data";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import AlertIcon from "../../../Common/Svgs/AlertIcon";
import { InfoCircleSecondaryIcon } from "../../../Common/Svgs/InfoCircleIcon";

const AssignHubStationDropdown = ({ detail }) => {
  const { allLocations } = useSelector((state) => state.locations);

  // get all the hubs
  const hubStations = allLocations.filter(
    (item) => item.type === LOCATION_TYPE.HUB_STATION,
  );

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedHubs, setSelectedHubs] = useState([]);

  const filteredData = () => {
    return hubStations.filter((hub) =>
      hub.name.toLowerCase().includes(search.toLowerCase()),
    );
  };

  const handleClick = async (hub) => {
    const isHubSelected = selectedHubs.includes(hub.id);

    const payload = {
      userId: detail.id,
      hubId: hub.id,
      roleId: detail.roleId,
    };

    try {
      let response;

      // remove the user-hub entry
      if (isHubSelected) {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/remove-hub`,
          payload,
        );
      }
      // add a new user-hub entry
      else {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/assign-hub`,
          payload,
        );
      }

      // update the state
      setSelectedHubs((prev) =>
        isHubSelected
          ? prev.filter((item) => item !== hub.id)
          : [...prev, hub.id],
      );

      console.log(response.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1 max-h-[200px] overflow-y-scroll"
          style={{
            maxWidth: "250px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div className="px-1">
            <CustomInput
              intent="searchHome"
              placeholder="Search Role"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {filteredData().map((hub) => {
            const selected = selectedHubs.includes(hub.id);

            return (
              <button
                type="button"
                className="w-full flex items-center justify-between gap-x-3 px-3 py-2 group hover:bg-background"
                key={hub.id}
                onClick={() => handleClick(hub)}
              >
                <div className="flex items-center gap-x-2">
                  <Checkbox checked={selected} />

                  <p
                    className="font-aileron text-xs font-semibold capitalize group-hover:text-black"
                    style={{
                      color: selected ? "#000" : "#525252",
                    }}
                  >
                    {hub.name}
                  </p>
                </div>
              </button>
            );
          })}
        </div>
      }
    >
      {detail.id === 19 ? (
        <button type="button" className="flex items-center gap-x-2">
          <InfoCircleSecondaryIcon />

          <p className="font-aileron text-xs font-semibold text-yellow-dark capitalize">
            Assign hub station
          </p>

          <ChevronIcon
            strokeWidth="1"
            className={`mt-0.5 w-4 h-4 stroke-yellow-dark ${
              open ? "-rotate-90" : "rotate-90"
            }`}
          />
        </button>
      ) : (
        <button type="button" className="flex items-center gap-x-2">
          <p className="font-aileron text-xs font-semibold text-primary capitalize">
            2 Hub Stations
          </p>

          <ChevronIcon
            strokeWidth="1"
            className={`mt-0.5 w-4 h-4 stroke-primary ${
              open ? "-rotate-90" : "rotate-90"
            }`}
          />
        </button>
      )}
    </Popover>
  );
};

export default AssignHubStationDropdown;

export const AssignHubWhileCreation = ({
  selectedHubs,
  onSelect,
  hasError,
}) => {
  const { allLocations } = useSelector((state) => state.locations);

  // get all the hubs
  const hubStations = allLocations.filter(
    (item) => item.type === LOCATION_TYPE.HUB_STATION,
  );

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const filteredData = () => {
    return hubStations.filter((hub) =>
      hub.name.toLowerCase().includes(search.toLowerCase()),
    );
  };

  const getFieldText = () => {
    if (selectedHubs.length === 1) {
      return `${selectedHubs[0].name}`;
    }

    if (selectedHubs.length > 1) {
      return `${selectedHubs.length} Hub Stations`;
    }

    return "Assign One Or More Hub Stations";
  };

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      open={open}
      arrow={false}
      onOpenChange={(state) => setOpen(state)}
      content={
        <div
          className="rounded-md bg-background-white py-1 max-h-[200px] overflow-y-scroll"
          style={{
            width: "367px",
            boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          <div className="px-1">
            <CustomInput
              intent="searchHome"
              placeholder="Search Hub Station"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {filteredData().map((hub) => {
            const selected =
              selectedHubs.findIndex((item) => item.id === hub.id) > -1;

            return (
              <button
                type="button"
                className="w-full flex items-center justify-between gap-x-3 px-3 py-2 group hover:bg-background"
                key={hub.id}
                onClick={() => onSelect(hub)}
              >
                <div className="flex items-center gap-x-2">
                  <Checkbox checked={selected} />

                  <p
                    className="font-aileron text-xs font-semibold capitalize group-hover:text-black"
                    style={{
                      color: selected ? "#000" : "#525252",
                    }}
                  >
                    {hub.name}
                  </p>
                </div>
              </button>
            );
          })}
        </div>
      }
    >
      <div
        role="button"
        className={`px-3.5 py-2 w-[367px] bg-white flex items-center justify-between border rounded-md ${
          hasError
            ? "border-alert-red"
            : open
            ? "border-primary"
            : "border-light-grey"
        }`}
      >
        <p className="font-aileron text-xs font-semibold capitalize text-dark-gray">
          {getFieldText()}
        </p>

        {hasError ? (
          <AlertIcon className="text-alert-red" />
        ) : (
          <ChevronIcon
            strokeWidth="1"
            className={`w-5 h-5 stroke-dark-gray ${
              open ? "-rotate-90" : "rotate-90"
            }`}
          />
        )}
      </div>
    </Popover>
  );
};
