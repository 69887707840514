import React from "react";
import InviteAndDeleteAction from "./InviteAndDeleteAction";

export const tenantColumns = [
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Organisation
      </div>
    ),
    width: "15%",
    dataIndex: "organisation",
    sorter: (a, b) => a.organisation.localeCompare(b.organisation),
    render: (text) => (
      <div className="text-xs font-semibold text-black font-aileron py-2">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">Name</div>
    ),
    // width: "25%",
    dataIndex: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Email
      </div>
    ),
    width: "18%",
    dataIndex: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Contact
      </div>
    ),
    // width: "25%",
    dataIndex: "contact",
    sorter: (a, b) => a.contact.localeCompare(b.contact),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Assets
      </div>
    ),
    // width: "25%",
    dataIndex: "assets",
    sorter: (a, b) => a.assets.localeCompare(b.assets),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Drivers
      </div>
    ),
    // width: "25%",
    dataIndex: "drivers",
    sorter: (a, b) => a.drivers.localeCompare(b.drivers),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">Hubs</div>
    ),
    // width: "25%",
    dataIndex: "hubs",
    sorter: (a, b) => a.hubs.localeCompare(b.hubs),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Date of entry
      </div>
    ),
    // width: "25%",
    dataIndex: "createdAt",
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron py-2">
        {text}
      </div>
    ),
  },
  {
    title: () => null,
    // width: "25%",
    dataIndex: "createdAt",
    render: (text, record) => (
      <div>
        <InviteAndDeleteAction tenant={record} />
      </div>
    ),
  },
];

export const tenantData = [
  {
    id: 1,
    organisation: "Antarctica Global",
    name: "Kevin Shah",
    email: "kevin@antarcticaglobal.com",
    contact: "1234567890",
    assets: 20,
    drivers: 30,
    hubs: 40,
    createdAt: "01-10-2024",
    isActive: 1,
  },
  {
    id: 2,
    organisation: "Pebble",
    name: "Ganesh Mohanty",
    email: "ganesh@antarcticaglobal.com",
    contact: "1234567890",
    assets: 20,
    drivers: 30,
    hubs: 40,
    createdAt: "11-10-2024",
    isActive: 1,
  },
  {
    id: 3,
    organisation: "Songa Mobility",
    name: "Kevin Shah",
    email: "kevin@antarcticaglobal.com",
    contact: "1234567890",
    assets: 20,
    drivers: 30,
    hubs: 40,
    createdAt: "01-10-2024",
    isActive: 0,
  },
];
