import React from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LoggedUserItem = ({ sidebarActive }) => {
  const navigate = useNavigate();

  const { userDetails } = useSelector((state) => state.loggedInUser);

  const initial = userDetails?.firstName[0];

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <motion.div
      layout
      className="absolute bottom-[29px] px-[19px] flex items-center justify-between w-full"
    >
      <motion.div
        // layout
        className="w-[34px] h-[34px] absolute flex items-center justify-center bg-primary-50 rounded-full"
      >
        <p className="font-poppins text-base font-medium uppercase text-sidebar-active">
          {initial}
        </p>
      </motion.div>

      <div className="w-[34px] h-[34px]" />

      {sidebarActive && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="flex items-center gap-x-2 cursor-pointer"
          onClick={handleLogout}
        >
          <p className="font-aileron text-xs font-semibold text-sidebar-base">
            Logout
          </p>

          <img
            src="/assets/icons/logout.png"
            alt="logout"
            className="w-5 h-5"
          />
        </motion.div>
      )}
    </motion.div>
  );
};

export default LoggedUserItem;
