/* eslint-disable no-console */
import React, { useState } from "react";
import { Popover } from "antd";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import NotificationIcon, {
  NotificationIconWithRedDot,
} from "../../../Common/Svgs/NotificationIcon";
import { notificationList } from "./constants";
import NotificationItem from "./NotificationItem";

const EmptyState = () => {
  return (
    <div className="flex justify-center w-full h-[300px] px-2">
      <div className="mt-[100px] flex flex-col items-center gap-y-2">
        <NotificationIcon className="fill-light-grey stroke-light-grey" />

        <p className="text-sm font-semibold font-aileron text-dark-gray">
          No new notifications
        </p>
      </div>
    </div>
  );
};

const NotificationPopup = () => {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      placement="bottom"
      open={open}
      onOpenChange={(state) => setOpen(state)}
      arrow={false}
      trigger="click"
      content={
        <div className="w-[312px]">
          <div className="flex items-center justify-between px-6 pt-6">
            <h1 className="text-base font-medium text-new-black font-poppins">
              Alerts
            </h1>

            <CloseIcon
              className="w-5 h-5 cursor-pointer stroke-dark-grey-text"
              onClick={() => setOpen(false)}
            />
          </div>

          <div className="max-h-[400px] mt-2 overflow-y-scroll hide-scrollbar">
            {notificationList.length ? (
              <>
                {notificationList.map((item, index) => (
                  <NotificationItem
                    key={item.id}
                    notification={item}
                    isLast={index === notificationList.length - 1}
                  />
                ))}
              </>
            ) : (
              <EmptyState />
            )}
          </div>
        </div>
      }
    >
      <button type="button">
        {notificationList.length === 0 ? (
          <NotificationIcon className="fill-none stroke-black" />
        ) : (
          <NotificationIconWithRedDot className="fill-none stroke-black" />
        )}
      </button>
    </Popover>
  );
};

export default NotificationPopup;
