/* eslint-disable no-console */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import SettingsHeader from "../SettingsHeader";
import TenantHeader from "./TenantHeader";
import CustomTable from "../../../Core/Components/CustomTable";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { tenantColumns, tenantData } from "./constants";
import InviteModal from "./InviteModal";
import { updateTenants } from "../../../Redux/Slices/tenants.slice";
import ToastMessageModal from "./ToastMessageModal";
import DeleteModal from "./DeleteModal";

const Tenants = () => {
  const dispatch = useDispatch();

  const { inviteModal, deleteModal, showToastMessage } = useSelector(
    (state) => state.tenants,
  );

  const [currentPage, setCurrentPage] = useState(1);

  console.log(currentPage);

  // to clear the toast message
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateTenants({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch]);

  return (
    <CustomLayout pageName="Settings">
      <div className="w-full h-full bg-background-white">
        <SettingsHeader />

        <div className="p-6">
          <TenantHeader />

          <CustomTable
            scrollX={0}
            columns={tenantColumns}
            data={tenantData}
            rowClassName="group"
            pagination={{
              pageSize: 10,
              total: tenantData?.length,
              showSizeChanger: false,
              onChange: (page) => {
                setCurrentPage(page);
              },
              itemRender: (current, type, originalElement) => {
                const lastPage = tenantData?.length / current;

                if (type === "prev" && current === 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{ pointerEvents: "none" }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "prev" && current > 0) {
                  return (
                    <button
                      type="button"
                      className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next" && lastPage <= 10) {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                      style={{
                        pointerEvents: "none",
                      }}
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                if (type === "next") {
                  return (
                    <button
                      type="button"
                      className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                    >
                      <ChevronIcon className="stroke-dark-gray" />
                    </button>
                  );
                }

                return originalElement;
              },
            }}
          />
        </div>

        <InviteModal
          visible={inviteModal?.visible}
          onCancel={() =>
            dispatch(
              updateTenants({
                key: "inviteModal",
                value: {
                  visible: false,
                  tenant: {},
                },
              }),
            )
          }
        />

        <DeleteModal
          visible={deleteModal?.visible}
          onCancel={() =>
            dispatch(
              updateTenants({
                key: "deleteModal",
                value: {
                  visible: false,
                  tenant: {},
                },
              }),
            )
          }
        />

        <ToastMessageModal
          visible={showToastMessage?.visible}
          title={showToastMessage?.data?.title}
          description={showToastMessage?.data?.description}
          onCancel={() =>
            dispatch(
              updateTenants({
                key: "showToastMessage",
                value: {
                  visible: false,
                  data: {},
                },
              }),
            )
          }
        />
      </div>
    </CustomLayout>
  );
};

export default Tenants;
