import React from "react";
import SubmittedDetails from "../../../Core/Components/SubmittedDetails";
import ChargerStatus from "../ChargerStatus";
import { CHARGER_TYPE } from "./constants";
import ListDropdown from "../ListDropdown";

export const chargerColumns = [
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Serial no.
      </div>
    ),
    dataIndex: "serialNo",
    render: (text) => (
      <div>
        <div className="text-xs font-semibold font-aileron leading-4.5 text-black">
          {text}
        </div>
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        KSC Tag no.
      </div>
    ),
    dataIndex: "tagNo",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Charger Type
      </div>
    ),
    dataIndex: "chargerType",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text === CHARGER_TYPE.CANBUS ? "Canbus" : "Non-Canbus"}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Current
      </div>
    ),
    dataIndex: "currentValue",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Hub Station
      </div>
    ),
    dataIndex: "hubName",
    render: (text) => (
      <div className="text-xs font-semibold font-aileron leading-4.5 text-dark-gray capitalize">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-normal font-aileron text-10 text-dark-gray">
        Status
      </div>
    ),
    dataIndex: "status",
    render: (text) => <ChargerStatus status={text} />,
  },
  {
    title: () => (
      <div className="font-light font-poppins text-10 text-dark-gray">
        Date of Entry
      </div>
    ),
    sorter: (a, b) => a?.submittedAt?.localeCompare(b?.submittedAt),
    dataIndex: "submittedAt",
    width: "200px",
    render: (text, record) => <SubmittedDetails record={record} />,
  },
  {
    dataIndex: "id",
    width: "5%",
    render: (text, record) => (
      <button
        type="button"
        onClick={(event) => {
          event.stopPropagation();
        }}
        className="invisible group-hover:visible w-full flex items-center justify-center"
      >
        <ListDropdown detail={record} />
      </button>
    ),
  },
];

export const chargerData = [
  {
    id: 1,
    serialNo: "12345",
    tagNo: "KSC123",
    typeId: 1,
    typeName: "Canbus",
    currentId: 1,
    currentValue: "10 Amps",
    hubName: "Ogembo Hub",
    status: 1,
    submittedAt: "2024-11-10",
    submittedBy: 19,
  },
  {
    id: 2,
    serialNo: "12345",
    tagNo: "KSC123",
    typeId: 1,
    typeName: "Canbus",
    currentId: 1,
    currentValue: "10 Amps",
    hubName: "Ogembo Hub",
    status: 1,
    submittedAt: "2024-11-10",
    submittedBy: 19,
  },
  {
    id: 3,
    serialNo: "12345",
    tagNo: "KSC123",
    typeId: 2,
    typeName: "Non-Canbus",
    currentId: 2,
    currentValue: "10 Amps (1 Battery)",
    hubName: "Ogembo Hub",
    status: 2,
    submittedAt: "2024-11-10",
    submittedBy: 19,
  },
  {
    id: 4,
    serialNo: "12345",
    tagNo: "KSC123",
    typeId: 2,
    typeName: "Non-Canbus",
    currentId: 3,
    currentValue: "12 Amps (1 Battery)",
    hubName: "Ogembo Hub",
    status: 2,
    submittedAt: "2024-11-10",
    submittedBy: 19,
  },
  {
    id: 5,
    serialNo: "12345",
    tagNo: "KSC123",
    typeId: 1,
    typeName: "Canbus",
    currentId: 1,
    currentValue: "10 Amps",
    hubName: "Ogembo Hub",
    status: 2,
    submittedAt: "2024-11-10",
    submittedBy: 19,
  },
  {
    id: 6,
    serialNo: "12345",
    tagNo: "KSC123",
    typeId: 2,
    typeName: "Non-Canbus",
    currentId: 4,
    currentValue: "15 Amps (1-2 Batteries)",
    hubName: "Ogembo Hub",
    status: 1,
    submittedAt: "2024-11-10",
    submittedBy: 19,
  },
  {
    id: 7,
    serialNo: "12345",
    tagNo: "KSC123",
    typeId: 1,
    typeName: "Canbus",
    currentId: 1,
    currentValue: "10 Amps",
    hubName: "Ogembo Hub",
    status: 1,
    submittedAt: "2024-11-10",
    submittedBy: 19,
  },
  {
    id: 8,
    serialNo: "12345",
    tagNo: "KSC123",
    typeId: 1,
    typeName: "Canbus",
    currentId: 1,
    currentValue: "10 Amps",
    hubName: "Ogembo Hub",
    status: 1,
    submittedAt: "2024-11-10",
    submittedBy: 19,
  },
  {
    id: 9,
    serialNo: "12345",
    tagNo: "KSC123",
    typeId: 1,
    typeName: "Canbus",
    currentId: 1,
    currentValue: "10 Amps",
    hubName: "Ogembo Hub",
    status: 2,
    submittedAt: "2024-11-10",
    submittedBy: 19,
  },
  {
    id: 10,
    serialNo: "12345",
    tagNo: "KSC123",
    typeId: 2,
    typeName: "Non-Canbus",
    currentId: 5,
    currentValue: "20 Amps (1-3 Batteries)",
    hubName: "Ogembo Hub",
    status: 2,
    submittedAt: "2024-11-10",
    submittedBy: 19,
  },
];
