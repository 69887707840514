import "./App.css";
import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider, Spin } from "antd";
import BatteriesList from "./Modules/Battery/BatteryList";
import UsersList from "./Modules/Users/UsersList";
import ServiceList from "./Modules/Service/ServiceList";
import Home from "./Modules/Home/Home";
import Login from "./Modules/Login/Login";
import TrikesDetails from "./Modules/Trikes/TrikesDetails";
import BatteryDetails from "./Modules/Battery/BatteryDetails";
import ResetPassword from "./Modules/Login/ResetPassword";
import { store, persistor } from "./Redux/store";
import SetNewPassword from "./Modules/Login/SetNewPassword";
import { setupAxiosInterceptors } from "./Utils/axiosClient";
import InventoryList from "./Modules/Inventory/InventoryList";
import TripList from "./Modules/Trips/TripList";
import LocationList from "./Modules/Location/LocationList";
import Settings from "./Modules/Settings/Settings";
import GenerateUser from "./Modules/Settings/Users/GenerateUser";
import TripDetails from "./Modules/Trips/TripDetails/TripDetails";
import TripScreen from "./Modules/Trips/CreateTrip/TripScreen";
import getThemeColor from "./Utils/getThemeColor";
import AddTrike from "./Modules/Trikes/phase3/AddTrike";
import Checklist from "./Modules/Trikes/phase3/Checklist";
import AddBattery from "./Modules/Battery/phase3/AddBattery";
import AddDriver from "./Modules/Users/phase3/AddDriver";
import AddFarmer from "./Modules/Users/phase3/AddFarmer";
import AddLocation from "./Modules/Location/phase3/AddLocation";
import TrikeKnownIssues from "./Modules/Trikes/phase3/TrikeKnownIssues";
import QRCodeList from "./Modules/QRCode/QRCodeList";
import AddToService from "./Modules/Service/phase3/AddToService";
import CompleteService from "./Modules/Service/phase3/CompleteService";
import AppDownload from "./Modules/MobileApp/AppDownload";
import ProtectedRoute from "./Core/Components/ProtectedRoute";
import { PERMISSIONS } from "./Modules/Login/constants";
import SystemUsers from "./Modules/Settings/Users/SystemUsers";
import RolesAndPermissions from "./Modules/Settings/RolesAndPermissons/RolesAndPermissions";
import CreateRole from "./Modules/Settings/RolesAndPermissons/CreateRole";
import NoPathFound from "./Core/Components/NoPathFound";
import TrikesList from "./Modules/Trikes/phase3/TrikesList";
import Tenants from "./Modules/Settings/Tenants/Tenants";
import CreateTenant from "./Modules/Settings/Tenants/CreateTenant";
import TenantSignUp from "./Modules/Onboarding/TenantSignUp";
import OnboardingPage from "./Modules/Onboarding/OnboardingPage";
import AddHub from "./Modules/Location/phase3/AddHub";
import ChargerList from "./Modules/Charger/ChargerList";
import AddCharger from "./Modules/Charger/AddCharger";
import AssignBattery from "./Modules/Battery/phase3/AssignBatteries/AssignBattery";

const LoadingWrapper = ({ children }) => {
  const { theme, loading: loginLoading } = useSelector(
    (state) => state.loggedInUser,
  );

  const { loading: trikeLoading } = useSelector((state) => state.trikes);
  const { loading: batteryLoading } = useSelector((state) => state.batteries);
  const { loading: userLoading } = useSelector((state) => state.users);
  const { loading: serviceLoading } = useSelector((state) => state.services);
  const { loading: tripLoading } = useSelector((state) => state.trips);
  const { loading: locationLoading } = useSelector((state) => state.locations);
  const { loading: inventoryLoading } = useSelector((state) => state.inventory);
  const { loading: qrCodeLoading } = useSelector((state) => state.qrCode);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: getThemeColor(theme),
        },
        components: {
          Checkbox: {
            colorText: "#ffffff",
          },
        },
      }}
    >
      <Spin
        spinning={
          loginLoading ||
          trikeLoading ||
          batteryLoading ||
          userLoading ||
          serviceLoading ||
          tripLoading ||
          locationLoading ||
          inventoryLoading ||
          qrCodeLoading
        }
      >
        {children}
      </Spin>
    </ConfigProvider>
  );
};

function App() {
  setupAxiosInterceptors();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoadingWrapper>
          <BrowserRouter>
            <Routes>
              {/* Auth Routes - does not require permission check */}
              <Route path="/" element={<Login />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/change-password" element={<SetNewPassword />} />
              {/* Home Route - everyone will have view permission for Home */}
              <Route path="/home" element={<Home />} />
              {/* Trike Routes */}
              <Route
                path="/trikes"
                element={
                  <ProtectedRoute
                    component={<TrikesList />}
                    allowedPermissions={[
                      PERMISSIONS.TRIKE_VIEW,
                      PERMISSIONS.TRIKE_VIEW_AND_EDIT,
                    ]}
                  />
                }
              />
              <Route
                path="/trikes/:id"
                element={
                  <ProtectedRoute
                    component={<TrikesDetails />}
                    allowedPermissions={[
                      PERMISSIONS.TRIKE_VIEW,
                      PERMISSIONS.TRIKE_VIEW_AND_EDIT,
                    ]}
                  />
                }
              />
              <Route
                path="/add-trike"
                element={
                  <ProtectedRoute
                    component={<AddTrike />}
                    allowedPermissions={[PERMISSIONS.TRIKE_ADD]}
                  />
                }
              />
              <Route
                path="/edit-trike/:id"
                element={
                  <ProtectedRoute
                    component={<AddTrike />}
                    allowedPermissions={[PERMISSIONS.TRIKE_EDIT]}
                  />
                }
              />
              <Route
                path="/create-checklist"
                element={
                  <ProtectedRoute
                    component={<Checklist />}
                    allowedPermissions={[
                      PERMISSIONS.TRIKE_CHECKLIST_ADD,
                      PERMISSIONS.TRIKE_CHECKLIST_EDIT,
                      PERMISSIONS.TRIKE_CHECKLIST_DELETE,
                    ]}
                  />
                }
              />
              <Route
                path="/add-known-issue/:id"
                element={
                  <ProtectedRoute
                    component={<TrikeKnownIssues />}
                    allowedPermissions={[
                      PERMISSIONS.TRIKE_ISSUE_ADD,
                      PERMISSIONS.TRIKE_ISSUE_VIEW,
                    ]}
                  />
                }
              />
              {/* Battery Routes */}
              <Route
                path="/batteries"
                element={
                  <ProtectedRoute
                    component={<BatteriesList />}
                    allowedPermissions={[
                      PERMISSIONS.BATTERY_VIEW,
                      PERMISSIONS.BATTERY_VIEW_AND_EDIT,
                    ]}
                  />
                }
              />
              <Route
                path="/batteries/:id"
                element={
                  <ProtectedRoute
                    component={<BatteryDetails />}
                    allowedPermissions={[
                      PERMISSIONS.BATTERY_VIEW,
                      PERMISSIONS.BATTERY_VIEW_AND_EDIT,
                    ]}
                  />
                }
              />
              <Route
                path="/add-battery"
                element={
                  <ProtectedRoute
                    component={<AddBattery />}
                    allowedPermissions={[PERMISSIONS.BATTERY_ADD]}
                  />
                }
              />
              <Route
                path="/edit-battery/:id"
                element={
                  <ProtectedRoute
                    component={<AddBattery />}
                    allowedPermissions={[PERMISSIONS.BATTERY_EDIT]}
                  />
                }
              />
              <Route
                path="/assign-batteries/:hubId"
                element={<AssignBattery />}
              />

              {/* Charger Routes */}
              <Route path="/chargers" element={<ChargerList />} />

              <Route path="/add-charger" element={<AddCharger />} />

              <Route path="/edit-charger/:id" element={<AddCharger />} />

              {/* Trip Routes */}
              <Route
                path="/trips"
                element={
                  <ProtectedRoute
                    component={<TripList />}
                    allowedPermissions={[
                      PERMISSIONS.TRIP_VIEW,
                      PERMISSIONS.TRIP_VIEW_AND_EDIT,
                    ]}
                  />
                }
              />
              <Route
                path="/trips/create-trip"
                element={
                  <ProtectedRoute
                    component={<TripScreen />}
                    allowedPermissions={[PERMISSIONS.TRIP_CREATE]}
                  />
                }
              />
              <Route
                path="/trips/:id"
                element={
                  <ProtectedRoute
                    component={<TripDetails />}
                    allowedPermissions={[
                      PERMISSIONS.TRIP_VIEW,
                      PERMISSIONS.TRIP_VIEW_AND_EDIT,
                    ]}
                  />
                }
              />
              <Route
                path="/trips/:id/:stopId"
                element={
                  <ProtectedRoute
                    component={<TripDetails />}
                    allowedPermissions={[PERMISSIONS.TRIP_STOP_DETAILS]}
                  />
                }
              />
              <Route
                path="/trips/:id/:stopId/:farmerId"
                element={
                  <ProtectedRoute
                    component={<TripDetails />}
                    allowedPermissions={[PERMISSIONS.TRIP_STOP_DETAILS]}
                  />
                }
              />
              {/* Driver and Farmer Routes */}
              <Route
                path="/users"
                element={
                  <ProtectedRoute
                    component={<UsersList />}
                    allowedPermissions={[
                      PERMISSIONS.DRIVER_VIEW,
                      PERMISSIONS.DRIVER_VIEW_AND_EDIT,
                      PERMISSIONS.PARTNER_VIEW,
                      PERMISSIONS.PARTNER_VIEW_AND_EDIT,
                    ]}
                  />
                }
              />
              <Route
                path="/add-driver"
                element={
                  <ProtectedRoute
                    component={<AddDriver />}
                    allowedPermissions={[PERMISSIONS.DRIVER_ADD]}
                  />
                }
              />
              <Route
                path="/edit-driver/:id"
                element={
                  <ProtectedRoute
                    component={<AddDriver />}
                    allowedPermissions={[PERMISSIONS.DRIVER_EDIT]}
                  />
                }
              />
              <Route
                path="/add-farmer"
                element={
                  <ProtectedRoute
                    component={<AddFarmer />}
                    allowedPermissions={[PERMISSIONS.PARTNER_ADD]}
                  />
                }
              />
              <Route
                path="/edit-farmer/:id"
                element={
                  <ProtectedRoute
                    component={<AddFarmer />}
                    allowedPermissions={[PERMISSIONS.PARTNER_EDIT]}
                  />
                }
              />
              {/* Location Routes */}
              <Route
                path="/locations"
                element={
                  <ProtectedRoute
                    component={<LocationList />}
                    allowedPermissions={[
                      PERMISSIONS.LOCATIONS_VIEW,
                      PERMISSIONS.LOCATION_VIEW_AND_EDIT,
                    ]}
                  />
                }
              />
              <Route
                path="/add-location"
                element={
                  <ProtectedRoute
                    component={<AddLocation />}
                    allowedPermissions={[PERMISSIONS.LOCATION_ADD]}
                  />
                }
              />
              <Route
                path="/edit-location/:id"
                element={
                  <ProtectedRoute
                    component={<AddLocation />}
                    allowedPermissions={[PERMISSIONS.LOCATION_EDIT]}
                  />
                }
              />
              <Route
                path="/add-hub"
                element={
                  <ProtectedRoute
                    component={<AddHub />}
                    allowedPermissions={[PERMISSIONS.LOCATION_ADD]}
                  />
                }
              />
              <Route
                path="/edit-hub/:id"
                element={
                  <ProtectedRoute
                    component={<AddHub />}
                    allowedPermissions={[PERMISSIONS.LOCATION_EDIT]}
                  />
                }
              />
              {/* Inventory Route */}
              <Route
                path="/inventory"
                element={
                  <ProtectedRoute
                    component={<InventoryList />}
                    allowedPermissions={[PERMISSIONS.INVENTORY_VIEW]}
                  />
                }
              />
              {/* Service Routes */}
              <Route
                path="/service"
                element={
                  <ProtectedRoute
                    component={<ServiceList />}
                    allowedPermissions={[
                      PERMISSIONS.SERVICE_VIEW,
                      PERMISSIONS.SERVICE_VIEW_AND_EDIT,
                    ]}
                  />
                }
              />
              <Route
                path="/add-to-service/:type/:id"
                element={
                  <ProtectedRoute
                    component={<AddToService />}
                    allowedPermissions={[
                      PERMISSIONS.TRIKE_ADD_TO_SERVICE,
                      PERMISSIONS.BATTERY_ADD_TO_SERVICE,
                    ]}
                  />
                }
              />
              <Route
                path="/complete-service/:type/:id"
                element={
                  <ProtectedRoute
                    component={<CompleteService />}
                    allowedPermissions={[
                      PERMISSIONS.SERVICE_VIEW,
                      PERMISSIONS.SERVICE_VIEW_AND_EDIT,
                    ]}
                  />
                }
              />
              {/* QR Code Route */}
              <Route
                path="/qr-codes"
                element={
                  <ProtectedRoute
                    component={<QRCodeList />}
                    allowedPermissions={[
                      PERMISSIONS.TRIKE_VIEW_QR,
                      PERMISSIONS.BATTERY_VIEW_QR,
                      PERMISSIONS.PARTNER_VIEW_QR,
                    ]}
                  />
                }
              />
              {/* Settings Routes */}
              {/* everyone will have access to settings module. 
                  Granular permissions added section wise */}
              <Route path="/settings" element={<Settings />} />
              <Route
                path="/settings/system-users"
                element={
                  <ProtectedRoute
                    component={<SystemUsers />}
                    allowedPermissions={[
                      PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW,
                      PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW_AND_EDIT,
                    ]}
                  />
                }
              />
              <Route
                path="/settings/system-users/create-user"
                element={
                  <ProtectedRoute
                    component={<GenerateUser />}
                    allowedPermissions={[
                      PERMISSIONS.SETTINGS_SYSTEM_USER_CREATE,
                    ]}
                  />
                }
              />
              {/* removed the edit user in maintenance phase 3 */}
              {/* <Route
                path="/settings/system-users/edit-user/:id"
                element={<GenerateUser />}
              /> */}
              <Route
                path="/settings/roles-and-permissions"
                element={
                  <ProtectedRoute
                    component={<RolesAndPermissions />}
                    allowedPermissions={[
                      PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW,
                      PERMISSIONS.SETTINGS_SYSTEM_USER_VIEW_AND_EDIT,
                    ]}
                  />
                }
              />
              <Route
                path="/settings/roles-and-permissions/create-role"
                element={
                  <ProtectedRoute
                    component={<CreateRole />}
                    allowedPermissions={[PERMISSIONS.SETTINGS_ROLE_CREATE]}
                  />
                }
              />
              <Route
                path="/settings/roles-and-permissions/edit-role/:id"
                element={
                  <ProtectedRoute
                    component={<CreateRole />}
                    allowedPermissions={[PERMISSIONS.SETTINGS_ROLE_EDIT]}
                  />
                }
              />
              {/* tenants route */}
              {/* TODO: wrap inside the Protected route and update the permissions */}
              <Route path="/settings/tenants" element={<Tenants />} />
              <Route
                path="/settings/tenants/create-tenant"
                element={<CreateTenant />}
              />
              <Route path="/tenant-sign-up" element={<TenantSignUp />} />
              {/* onboarding route */}
              <Route path="/onboarding" element={<OnboardingPage />} />
              {/* App download Route */}
              <Route path="/download" element={<AppDownload />} />
              {/* If no path is found */}
              <Route path="*" element={<NoPathFound />} />
            </Routes>
          </BrowserRouter>
        </LoadingWrapper>
      </PersistGate>
    </Provider>
  );
}

export default App;
