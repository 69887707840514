/* eslint-disable no-console */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../Core/Components/CustomButton";
import renderOriginDestination from "./renderOriginDestination";
import CustomTripComponent from "./CustomTripComponent";
import ToastMessageModal from "../ToastMessageModal";
import { updateTrips } from "../../../Redux/Slices/trips.slice";
import FullScreenMapForTrips from "../FullScreenMapForTrips";
import { optimiseRoute } from "../../../Redux/APIs/tripsAPI";
import snapToRoad, { fastestRoute } from "../TripDetails/snapToRoad";
import OptimisingRouteModal from "./OptimisingRouteModal";
import RouteInformation from "./RouteInformation";
import OptimisationNotPossibleModal, {
  MissingActivityModal,
} from "./OptimisationNotPossibleModal";
import CalculateTimeAndDistance from "./CalculateTimeAndDistance";

const StepLine = () => {
  return <div className="bg-yellow-dark w-[1px] h-[27px]" />;
};

const OptimiseRoute = ({ setCoordinatesForStops, setMapLoading }) => {
  const dispatch = useDispatch();
  const { allLocations } = useSelector((state) => state.locations);

  const {
    stops,
    fullScreenMapForTrips,
    isRouteOptimised,
    missingActivityModal,
  } = useSelector((state) => state.trips);

  const getCoordinatesForStops = () => {
    const coordinatesForLocations = stops.map((stop) => {
      const currentLocation = allLocations?.find(
        (location) => location.name === stop.location,
      );

      if (currentLocation) return [currentLocation.lat, currentLocation.long];
      return [0, 0];
    });

    setCoordinatesForStops([...coordinatesForLocations]);
    return coordinatesForLocations;
  };

  const renderText = (index, locationName) => {
    let stopName = locationName;

    if (stopName === "Stop") {
      stopName = `${stopName} ${index}`;
    }
    return stopName;
  };

  const [error, setError] = useState(false);
  const [fastestRouteError, setFastestRouteError] = useState(false);

  const stopCoordinates = stops.slice(0, -1).map((stop) => {
    const currentLocation = allLocations?.find(
      (location) => location.name === stop.location,
    );

    if (currentLocation) {
      return [currentLocation.lat, currentLocation.long];
    }

    return [0, 0];
  });

  const snapToRoadAPICall = async () => {
    const snapCoordinates = await snapToRoad(allLocations, stops, setError);

    console.log(error, "check the value of error");

    if (error) {
      dispatch(
        updateTrips({
          key: "fastestRouteCoordinates",
          value: stopCoordinates,
        }),
      );
    } else {
      dispatch(
        updateTrips({
          key: "snapToRoadCoordinates",
          value: snapCoordinates,
        }),
      );

      const fastestRouteCoordinates = await fastestRoute(
        snapCoordinates,
        setFastestRouteError,
      );

      if (fastestRouteError) {
        dispatch(
          updateTrips({
            key: "fastestRouteCoordinates",
            value: stopCoordinates,
          }),
        );
      } else {
        dispatch(
          updateTrips({
            key: "fastestRouteCoordinates",
            value: fastestRouteCoordinates,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (stops.every((stop) => stop.location !== "Stop")) {
      getCoordinatesForStops();
    }
  }, [stops]);

  const findMissingActivitiesForCargos = () => {
    const uniqueCargos = [
      ...new Set(
        stops.flatMap((stop) => stop.activities.map((act) => act.cargo)),
      ),
    ];

    const missingActivities = uniqueCargos.reduce((result, cargo) => {
      const collectionExists = stops.some((stop) =>
        stop.activities.some(
          (act) =>
            (act.activity === "collection" || act.activity === "aggregation") &&
            act.cargo === cargo,
        ),
      );

      const deliveryExists = stops.some((stop) =>
        stop.activities.some(
          (act) => act.activity === "delivery" && act.cargo === cargo,
        ),
      );

      if (!collectionExists) {
        result.push({ cargo, activity: "collection" });
      }

      if (!deliveryExists) {
        result.push({ cargo, activity: "delivery" });
      }

      return result;
    }, []);

    return missingActivities;
  };

  const handleOptimiseRoute = () => {
    const missingActivities = findMissingActivitiesForCargos();

    if (missingActivities.length > 0) {
      dispatch(
        updateTrips({
          key: "missingActivityModal",
          value: { ...missingActivityModal, visible: true, missingActivities },
        }),
      );
    } else {
      dispatch(updateTrips({ key: "optimisingRouteModal", value: true }));
      try {
        dispatch(optimiseRoute({ stops })).then(() => {
          dispatch(updateTrips({ key: "optimisingRouteModal", value: false }));
          dispatch(updateTrips({ key: "isRouteOptimised", value: true }));
        });
      } catch (err) {
        dispatch(updateTrips({ key: "optimisingRouteModal", value: false }));
        console.log(err);
      }
    }
  };

  const handleMap = () => {
    dispatch(
      updateTrips({
        key: "fullScreenMapForTrips",
        value: {
          ...fullScreenMapForTrips,
          visible: true,
          pointColor: 1,
        },
      }),
    );
    setMapLoading(true);
    snapToRoadAPICall().then(() => setMapLoading(false));
  };

  return (
    <div className="h-full pr-6 border-r border-light-grey">
      <div className="flex items-center gap-x-2">
        <Button
          size="primary"
          colors="optimiseRoute"
          width="optimiseRoute"
          type="submit"
          onClick={() => handleOptimiseRoute()}
        >
          Optimise Route
        </Button>
        <button
          type="button"
          className="h-[42px] px-4 py-3 rounded-md bg-primary"
          onClick={() => handleMap()}
        >
          <img src="/assets/map.svg" className="w-4 h-4" alt="" />
        </button>
      </div>
      <div className="mt-4">
        {stops.map((location, index) => (
          <div className="flex justify-between">
            <div className="flex items-start gap-x-2">
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-4 h-4 font-semibold text-white rounded-full bg-yellow-dark font-aileron text-10">
                  {index + 1}
                </div>
                {index !== stops.length - 1 && <StepLine />}
              </div>
              <p className="font-semibold capitalize text-dark-gray font-aileron text-10">
                {renderText(location.id, location.location)}
              </p>
            </div>
            <div>{renderOriginDestination(location.id, stops.length)}</div>
          </div>
        ))}
        <div className="mt-4">
          <CalculateTimeAndDistance />
        </div>
      </div>
      {isRouteOptimised ? (
        <div className="mt-4">
          <RouteInformation />
        </div>
      ) : (
        <div className="w-[312px]" />
      )}
    </div>
  );
};

const AddStops = () => {
  const dispatch = useDispatch();
  const { stops } = useSelector((state) => state.trips);

  const draggedStop = React.useRef(null);
  const draggedOverStop = React.useRef(null);

  const handleSort = () => {
    const stopsClone = [...stops];
    if (
      draggedOverStop.current !== 0 &&
      draggedOverStop.current !== stops.length - 1 &&
      draggedStop.current !== 0 &&
      draggedStop.current !== stops.length - 1
    ) {
      const temp = stopsClone[draggedStop.current];
      stopsClone[draggedStop.current] = stopsClone[draggedOverStop.current];
      stopsClone[draggedOverStop.current] = temp;
      dispatch(updateTrips({ key: "stops", value: stopsClone }));
    }
  };

  console.log(stops, "check stops");

  return (
    <div className="p-6 h-[calc(100vh-285px)] w-full overflow-y-scroll hide-scrollbar">
      {stops?.map((t, index) => (
        <div
          key={t.id}
          draggable
          onDragStart={() => {
            draggedStop.current = index;
          }}
          onDragEnter={() => {
            draggedOverStop.current = index;
          }}
          onDragEnd={handleSort}
          onDragOver={(e) => {
            e.preventDefault();
          }}
        >
          <CustomTripComponent
            data={t}
            totalLength={stops.length}
            currentIndex={index}
          />
        </div>
      ))}
    </div>
  );
};

const TripStep1 = () => {
  const dispatch = useDispatch();
  const {
    toastMessage,
    fullScreenMapForTrips,
    optimisingRouteModal,
    optimisationNotPossibleModal,
    missingActivityModal,
  } = useSelector((state) => state.trips);

  const [coordinatesForStops, setCoordinatesForStops] = useState([]);
  const [mapLoading, setMapLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        updateTrips({
          key: "toastMessage",
          value: { ...toastMessage, visible: false },
        }),
      );
    }, 10000);
  }, [dispatch, toastMessage]);

  return (
    <div className="flex items-start w-full h-full gap-x-6">
      <OptimiseRoute
        setCoordinatesForStops={setCoordinatesForStops}
        setMapLoading={setMapLoading}
      />
      <AddStops />
      <div className="h-full">
        <div>
          <ToastMessageModal
            visible={toastMessage.visible}
            onCancel={() =>
              dispatch(
                updateTrips({
                  key: "toastMessage",
                  value: { ...toastMessage, visible: false },
                }),
              )
            }
          />
        </div>
      </div>
      <div className="h-full">
        <OptimisingRouteModal
          visible={optimisingRouteModal}
          onCancel={() =>
            dispatch(updateTrips({ key: "optimisingRouteModal", value: false }))
          }
        />
      </div>
      <div className="h-full">
        <OptimisationNotPossibleModal
          visible={optimisationNotPossibleModal}
          onCancel={() =>
            dispatch(
              updateTrips({
                key: "optimisationNotPossibleModal",
                value: false,
              }),
            )
          }
        />
      </div>
      <div className="h-full">
        <MissingActivityModal
          visible={missingActivityModal.visible}
          onCancel={() =>
            dispatch(
              updateTrips({
                key: "missingActivityModal",
                value: {
                  ...missingActivityModal,
                  visible: false,
                },
              }),
            )
          }
        />
      </div>
      {fullScreenMapForTrips.visible && (
        <FullScreenMapForTrips
          visible={fullScreenMapForTrips.visible}
          onCancel={() =>
            dispatch(
              updateTrips({
                key: "fullScreenMapForTrips",
                value: { ...fullScreenMapForTrips, visible: false },
              }),
            )
          }
          polygonCoordinates={coordinatesForStops.slice(0, -1)}
          loading={mapLoading}
        />
      )}
    </div>
  );
};

export default TripStep1;
