import React from "react";

const FinanceIcon = ({ size, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <g>
          <g>
            <path
              stroke="#AABDBD"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M8.324 14.983a6.658 6.658 0 100-13.316 6.658 6.658 0 000 13.316z"
            />
            <path
              stroke="#AABDBD"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M10.816 16.567a4.12 4.12 0 003.375 1.75 4.13 4.13 0 004.125-4.125 4.134 4.134 0 00-1.725-3.359"
            />
            <path
              fill="#AABDBD"
              d="M6.034 11.667h1.04a.2.2 0 00.2-.2V9.486a.2.2 0 01.07-.152l.288-.248a.2.2 0 01.297.041l1.633 2.45a.2.2 0 00.167.09h.644a.2.2 0 00.163-.316l-2.03-2.88a.2.2 0 01.023-.257l.164-.164A.2.2 0 019 8.079l2.38 3.5a.2.2 0 00.166.088h.58a.2.2 0 00.165-.312L9.638 7.41a.2.2 0 01.025-.253l1.814-1.815A.2.2 0 0011.336 5h-1.254a.2.2 0 00-.14.057l-2.33 2.265a.2.2 0 01-.339-.144V5.2a.2.2 0 00-.2-.2H6.034a.2.2 0 00-.2.2v6.267c0 .11.09.2.2.2z"
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export const FinanceHoverIcon = ({ size, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="#FAD152"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.324 14.983a6.658 6.658 0 100-13.316 6.658 6.658 0 000 13.316zM10.816 16.567a4.12 4.12 0 003.375 1.75 4.13 4.13 0 004.125-4.125 4.134 4.134 0 00-1.725-3.359"
        />
        <path
          fill="#FAD152"
          d="M6.034 11.667h1.04a.2.2 0 00.2-.2V9.486a.2.2 0 01.07-.152l.288-.248a.2.2 0 01.297.041l1.633 2.45a.2.2 0 00.167.09h.644a.2.2 0 00.163-.316l-2.03-2.88a.2.2 0 01.023-.257l.164-.164A.2.2 0 019 8.079l2.38 3.5a.2.2 0 00.166.088h.58a.2.2 0 00.165-.312L9.638 7.41a.2.2 0 01.025-.253l1.814-1.815A.2.2 0 0011.336 5h-1.254a.2.2 0 00-.14.057l-2.33 2.265a.2.2 0 01-.339-.144V5.2a.2.2 0 00-.2-.2H6.034a.2.2 0 00-.2.2v6.267c0 .11.09.2.2.2z"
        />
      </svg>
    </span>
  );
};

export const FinanceActiveIcon = ({ size, className }) => {
  return (
    <span className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#FAD152"
          stroke="#FAD152"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8.324 14.983a6.658 6.658 0 100-13.317 6.658 6.658 0 000 13.317z"
        />
        <path
          stroke="#FAD152"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M10.816 16.567a4.12 4.12 0 003.375 1.75 4.13 4.13 0 004.125-4.125 4.134 4.134 0 00-1.725-3.358"
        />
        <path
          fill="#085054"
          d="M6.034 11.667h1.04a.2.2 0 00.2-.2V9.486a.2.2 0 01.07-.152l.288-.248a.2.2 0 01.297.041l1.633 2.45a.2.2 0 00.167.09h.644a.2.2 0 00.163-.316l-2.03-2.88a.2.2 0 01.023-.257l.164-.164A.2.2 0 019 8.079l2.38 3.5a.2.2 0 00.166.088h.58a.2.2 0 00.165-.312L9.638 7.41a.2.2 0 01.025-.253l1.814-1.815A.2.2 0 0011.336 5h-1.254a.2.2 0 00-.14.057l-2.33 2.265a.2.2 0 01-.339-.144V5.2a.2.2 0 00-.2-.2H6.034a.2.2 0 00-.2.2v6.267c0 .11.09.2.2.2z"
        />
      </svg>
    </span>
  );
};

export default FinanceIcon;
