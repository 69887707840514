/* eslint-disable no-console */
/* eslint-disable react/no-unstable-nested-components */
import React, { memo, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CustomInputWithController } from "../../Core/Components/CustomInput";
import Button from "../../Core/Components/CustomButton";
import BlockedEyeIcon from "../../Common/Svgs/BlockedEyeIcon";

const TenantSignUp = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { handleSubmit, control, formState, getValues, setValue } = useForm({
    defaultValues: {
      name: "",
      password: "",
      confirmPassword: "",
    },
  });

  const localPassword = getValues("password");
  const localConfirmPassword = getValues("confirmPassword");

  const [tenantEmail, setTenantEmail] = useState("");
  const [focus, setFocus] = useState({
    name: false,
    password: false,
    confirmPassword: false,
  });
  const [showIcon, setShowIcon] = useState({
    password: false,
    confirmPassword: false,
  });

  const onSubmit = async (e) => {
    console.log(e);
    navigate("/onboarding");
  };

  const validationForName = {
    required: "Required.",
  };

  const validationForPassword = {
    required: "please enter password",
    minLength: {
      value: 8,
      message: "less characters for password",
    },
  };

  useEffect(() => {
    if (searchParams.has("token")) {
      const token = searchParams.get("token");
      setTenantEmail(token);
    }
  }, [searchParams, setValue]);

  return (
    <div className="w-screen h-screen">
      <Row className="h-full">
        <Col span={15} className="h-full overflow-hidden">
          <img
            src="/assets/images/Login/login-image.svg"
            alt="LoginImage"
            className="object-cover w-full h-full"
          />
        </Col>

        <Col span={9}>
          <div className="flex flex-col items-center justify-center w-full h-full gap-y-6 bg-background-white">
            <img
              src="/assets/songa-main-logo.svg"
              alt="Songa Mobility"
              className="w-[258px] h-14"
            />

            <div>
              <p className="font-poppins text-base font-medium text-center capitalize">
                Let’s get you started!
              </p>

              <p className="mt-2 font-poppins text-xs font-light text-center text-dark-gray">
                Please create your account.
              </p>
            </div>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col items-center justify-center w-full gap-y-6"
            >
              <CustomInputWithController
                intent="login"
                control={control}
                formState={formState}
                focus={focus.name}
                setFocus={(e) => setFocus({ ...focus, name: e })}
                name="name"
                placeholder="Enter Your Name"
                rules={validationForName}
              />

              <div className="h-9 w-[312px] bg-background border border-light-grey rounded-md py-2 px-4 cursor-not-allowed">
                <p className="font-aileron text-xs font-normal text-dark-gray">
                  {tenantEmail || "kevin@antarcticaglobal.com"}
                </p>
              </div>

              <CustomInputWithController
                inputType={showIcon.password ? "text" : "password"}
                intent="login"
                control={control}
                formState={formState}
                focus={focus.password}
                setFocus={(e) => setFocus({ ...focus, password: e })}
                name="password"
                placeholder="Set Password"
                rules={validationForPassword}
                suffix={
                  <button
                    type="button"
                    onClick={() => {
                      if (localPassword) {
                        setShowIcon((e) => ({ ...e, password: !e.password }));
                      }
                    }}
                    disabled={!localPassword}
                  >
                    {showIcon.password ? (
                      <img src="/images/eye.png" className="w-5 h-5" alt="" />
                    ) : (
                      <BlockedEyeIcon
                        className={
                          localPassword ? "text-black" : "text-dark-gray"
                        }
                      />
                    )}
                  </button>
                }
              />

              <CustomInputWithController
                inputType={showIcon.confirmPassword ? "text" : "password"}
                intent="login"
                control={control}
                formState={formState}
                focus={focus.confirmPassword}
                setFocus={(e) => setFocus({ ...focus, confirmPassword: e })}
                name="confirmPassword"
                placeholder="Confirm Password"
                rules={validationForPassword}
                suffix={
                  <button
                    type="button"
                    onClick={() => {
                      if (localConfirmPassword) {
                        setShowIcon((e) => ({
                          ...e,
                          confirmPassword: !e.confirmPassword,
                        }));
                      }
                    }}
                    disabled={!localConfirmPassword}
                  >
                    {showIcon.confirmPassword ? (
                      <img src="/images/eye.png" className="w-5 h-5" alt="" />
                    ) : (
                      <BlockedEyeIcon
                        className={
                          localConfirmPassword ? "text-black" : "text-dark-gray"
                        }
                      />
                    )}
                  </button>
                }
              />

              <Button type="submit" width="login">
                Sign Up
              </Button>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default memo(TenantSignUp);
