import { Table } from "antd";
import React from "react";

/**
 * @param  {array} data
 * @param  {array} columns
 * @param  {number} current
 * @param  {number} pageSize
 * @param  {function} setPageSize
 * @param  {function} setCurrent
 * @param  {string} customPadding
 * @param  {string} rowClassName
 * @param  {bool} loading
 */

export default function CustomTable({
  data,
  columns,
  scrollX = 0,
  scrollY = "350px",
  rowClassName,
  loading,
  emptyState,
  onRowClick = () => {},
  pagination = false,
  onTableChange = () => {},
  customTableClassName = "",
}) {
  const locale = {
    emptyText: (
      <div
        className="w-full flex items-center justify-center"
        style={{
          height: "320px",
        }}
      >
        <div className="flex flex-col gap-y-1.5 items-center justify-center">
          <div className="flex items-center justify-center h-full">
            <div className="w-9.5 h-9.5 bg-light-grey rounded-full flex items-center justify-center">
              {emptyState}
            </div>
          </div>

          <p className="font-light text-10 leading-3.75 font-poppins text-dark-grey-text">
            Nothing to show
          </p>
        </div>
      </div>
    ),
  };

  return (
    <Table
      locale={locale}
      key={data?.id}
      columns={columns}
      dataSource={data}
      rowKey="id"
      bordered
      scroll={
        data.length
          ? {
              y: scrollY,
              x: scrollX,
            }
          : null
      }
      showSorterTooltip={false}
      pagination={data.length ? pagination : false}
      rowClassName={rowClassName}
      className={`table-shadow rounded-2xl ${
        customTableClassName || "max-w-screen"
      }`}
      loading={loading}
      onRow={(record) => {
        return {
          onClick: () => onRowClick(record),
        };
      }}
      onChange={onTableChange}
    />
  );
}
