/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import { updateTenants } from "../../../Redux/Slices/tenants.slice";
import { TrashSecondaryIcon } from "../../../Common/Svgs/TrashIcon";

const DeleteModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();

  const { deleteModal } = useSelector((state) => state.tenants);

  const handleDelete = () => {
    dispatch(
      updateTenants({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: "1 Tenant Deleted",
            description: `${deleteModal?.tenant?.organisation} was deleted successfully.`,
          },
        },
      }),
    );

    onCancel();
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px">
      <div
        className="p-4"
        style={{
          boxShadow:
            "12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <div className="flex items-center justify-between">
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-primary relative">
            <img
              src="/assets/invite-tenant.png"
              className="w-6 h-6"
              alt="invite tenant"
            />

            <div className="absolute top-0 right-0">
              <TrashSecondaryIcon className="text-alert-red" />
            </div>
          </div>

          <button type="button" className="mr-2" onClick={onCancel}>
            <CloseIcon className="stroke-dark-gray w-6 h-6" />
          </button>
        </div>

        <div className="mt-5 px-2 pb-2">
          <h1 className="font-poppins text-base font-medium text-alert-red">
            Delete Tenant?
          </h1>

          <p className="mt-2 font-aileron text-xs font-normal">
            Are you sure you want to delete this tenant from the system?
          </p>

          <p className="mt-2 font-aileron text-xs font-normal">
            They will lose access to all their data and users.
          </p>

          <div className="mt-11 flex justify-end gap-x-3">
            <Button colors="grey" size="primary" onClick={onCancel}>
              Cancel
            </Button>
            <Button colors="alert" size="primary" onClick={handleDelete}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteModal;
