import React from "react";
import { useSelector } from "react-redux";
import Layout from "antd/es/layout/layout";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar/Main";

const CustomLayout = ({
  pageName,
  nestedName,
  children,
  hideFooter = false,
}) => {
  const { theme } = useSelector((state) => state.loggedInUser);

  return (
    <Layout
      className={` ${theme} flex-row w-screen h-screen overflow-hidden relative`}
    >
      <Sidebar pageName={pageName} />

      {/* ml-18 === size of the sidebar when inactive */}
      <Layout className="ml-18">
        <Header pageName={pageName} nestedName={nestedName} />
        <div className="w-full h-full">{children}</div>
        {hideFooter ? null : <Footer />}
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
