import { PERMISSIONS } from "../../Login/constants";

const MULTIPLE_INSERTION = {
  TRIKE_ADD: [
    PERMISSIONS.TRIKE_ADD,
    PERMISSIONS.TRIKE_EDIT,
    PERMISSIONS.TRIKE_DELETE,
  ],
  TRIKE_CHECKLIST_ADD: [
    PERMISSIONS.TRIKE_CHECKLIST_ADD,
    PERMISSIONS.TRIKE_CHECKLIST_EDIT,
    PERMISSIONS.TRIKE_CHECKLIST_DELETE,
  ],
  TRIKE_GEOFENCE_ADD: [
    PERMISSIONS.TRIKE_GEOFENCE_ADD,
    PERMISSIONS.TRIKE_GEOFENCE_EDIT,
    PERMISSIONS.TRIKE_GEOFENCE_DELETE,
  ],
  TRIKE_ISSUE_ADD: [PERMISSIONS.TRIKE_ISSUE_ADD, PERMISSIONS.TRIKE_ISSUE_VIEW],
  BATTERY_ADD: [
    PERMISSIONS.BATTERY_ADD,
    PERMISSIONS.BATTERY_EDIT,
    PERMISSIONS.BATTERY_DELETE,
  ],
  TRIP_CREATE: [
    PERMISSIONS.TRIP_CREATE,
    PERMISSIONS.TRIP_EDIT,
    PERMISSIONS.TRIP_DELETE,
  ],
  DRIVER_ADD: [
    PERMISSIONS.DRIVER_ADD,
    PERMISSIONS.DRIVER_EDIT,
    PERMISSIONS.DRIVER_DELETE,
  ],
  PARTNER_ADD: [
    PERMISSIONS.PARTNER_ADD,
    PERMISSIONS.PARTNER_EDIT,
    PERMISSIONS.PARTNER_DELETE,
  ],
  LOCATION_ADD: [
    PERMISSIONS.LOCATION_ADD,
    PERMISSIONS.LOCATION_EDIT,
    PERMISSIONS.LOCATION_DELETE,
  ],
  SYSTEM_USER_CREATE: [
    PERMISSIONS.SETTINGS_SYSTEM_USER_CREATE,
    PERMISSIONS.SETTINGS_SYSTEM_USER_EDIT,
    PERMISSIONS.SETTINGS_SYSTEM_USER_DELETE,
  ],
  ROLE_CREATE: [
    PERMISSIONS.SETTINGS_ROLE_CREATE,
    PERMISSIONS.SETTINGS_ROLE_EDIT,
    PERMISSIONS.SETTINGS_ROLE_DELETE,
  ],
};

// trike permission update functions
export const trikeAddEditDelete = ({ setPermissions }) => {
  setPermissions((prev) => {
    const newPermissions = [
      ...new Set([...prev, ...MULTIPLE_INSERTION.TRIKE_ADD]),
    ];
    return newPermissions;
  });
};

export const trikeChecklistAddEditDelete = ({ setPermissions }) => {
  setPermissions((prev) => {
    const newPermissions = [
      ...new Set([...prev, ...MULTIPLE_INSERTION.TRIKE_CHECKLIST_ADD]),
    ];
    return newPermissions;
  });
};

export const trikeGeofenceAddEditDelete = ({ setPermissions }) => {
  setPermissions((prev) => {
    const newPermissions = [
      ...new Set([...prev, ...MULTIPLE_INSERTION.TRIKE_GEOFENCE_ADD]),
    ];
    return newPermissions;
  });
};

export const trikeAddService = ({ current, permissions, setPermissions }) => {
  if (current === PERMISSIONS.TRIKE_ADD_TO_SERVICE) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.TRIKE_NO_SERVICE,
    );
    setPermissions(() => [...local, current]);
  }

  if (current === PERMISSIONS.TRIKE_NO_SERVICE) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.TRIKE_ADD_TO_SERVICE,
    );

    setPermissions(() => [...local, current]);
  }
};

export const trikeViewQR = ({ current, permissions, setPermissions }) => {
  if (current === PERMISSIONS.TRIKE_VIEW_QR) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.TRIKE_NO_QR,
    );
    setPermissions(() => [...local, current]);
  }

  if (current === PERMISSIONS.TRIKE_NO_QR) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.TRIKE_VIEW_QR,
    );

    setPermissions(() => [...local, current]);
  }
};

// battery permission update functions
export const batteryAddEditDelete = ({ setPermissions }) => {
  setPermissions((prev) => {
    const newPermissions = [
      ...new Set([...prev, ...MULTIPLE_INSERTION.BATTERY_ADD]),
    ];
    return newPermissions;
  });
};

export const batteryAddService = ({ current, permissions, setPermissions }) => {
  if (current === PERMISSIONS.BATTERY_ADD_TO_SERVICE) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.BATTERY_NO_SERVICE,
    );
    setPermissions(() => [...local, current]);
  }

  if (current === PERMISSIONS.BATTERY_NO_SERVICE) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.BATTERY_ADD_TO_SERVICE,
    );

    setPermissions(() => [...local, current]);
  }
};

export const batteryViewQR = ({ current, permissions, setPermissions }) => {
  if (current === PERMISSIONS.BATTERY_VIEW_QR) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.BATTERY_NO_QR,
    );
    setPermissions(() => [...local, current]);
  }

  if (current === PERMISSIONS.BATTERY_NO_QR) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.BATTERY_VIEW_QR,
    );

    setPermissions(() => [...local, current]);
  }
};

// trip permission update functions
export const tripCreateEditDelete = ({ setPermissions }) => {
  setPermissions((prev) => {
    const newPermissions = [
      ...new Set([...prev, ...MULTIPLE_INSERTION.TRIP_CREATE]),
    ];
    return newPermissions;
  });
};

export const tripViewStopDetails = ({
  current,
  permissions,
  setPermissions,
}) => {
  if (current === PERMISSIONS.TRIP_STOP_DETAILS) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.TRIP_NO_STOP_DETAILS,
    );
    setPermissions(() => [...local, current]);
  }

  if (current === PERMISSIONS.TRIP_NO_STOP_DETAILS) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.TRIP_STOP_DETAILS,
    );

    setPermissions(() => [...local, current]);
  }
};

// driver permission update functions
export const driverAddEditDelete = ({ setPermissions }) => {
  setPermissions((prev) => {
    const newPermissions = [
      ...new Set([...prev, ...MULTIPLE_INSERTION.DRIVER_ADD]),
    ];
    return newPermissions;
  });
};

// farmer permission update functions
export const farmerAddEditDelete = ({ setPermissions }) => {
  setPermissions((prev) => {
    const newPermissions = [
      ...new Set([...prev, ...MULTIPLE_INSERTION.PARTNER_ADD]),
    ];
    return newPermissions;
  });
};

export const farmerViewQR = ({ current, permissions, setPermissions }) => {
  if (current === PERMISSIONS.PARTNER_VIEW_QR) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.PARTNER_NO_QR,
    );
    setPermissions(() => [...local, current]);
  }

  if (current === PERMISSIONS.PARTNER_NO_QR) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.PARTNER_VIEW_QR,
    );

    setPermissions(() => [...local, current]);
  }
};

export const farmerExport = ({ current, permissions, setPermissions }) => {
  if (current === PERMISSIONS.PARTNER_EXPORT) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.PARTNER_NO_EXPORT,
    );
    setPermissions(() => [...local, current]);
  }

  if (current === PERMISSIONS.PARTNER_NO_EXPORT) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.PARTNER_EXPORT,
    );

    setPermissions(() => [...local, current]);
  }
};

// location permission update function
export const locationAddEditDelete = ({ setPermissions }) => {
  setPermissions((prev) => {
    const newPermissions = [
      ...new Set([...prev, ...MULTIPLE_INSERTION.LOCATION_ADD]),
    ];
    return newPermissions;
  });
};

// settings permission update function
export const settingsPersonalisation = ({
  current,
  permissions,
  setPermissions,
}) => {
  if (current === PERMISSIONS.SETTINGS_PERSONALISATION) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.SETTINGS_NO_PERSONALISATION,
    );
    setPermissions(() => [...local, current]);
  }

  if (current === PERMISSIONS.SETTINGS_NO_PERSONALISATION) {
    const local = permissions.filter(
      (item) => item !== PERMISSIONS.SETTINGS_PERSONALISATION,
    );

    setPermissions(() => [...local, current]);
  }
};

export const systemUserCreateEditDelete = ({ setPermissions }) => {
  setPermissions((prev) => {
    const newPermissions = [
      ...new Set([...prev, ...MULTIPLE_INSERTION.SYSTEM_USER_CREATE]),
    ];
    return newPermissions;
  });
};

export const roleCreateEditDelete = ({ setPermissions }) => {
  setPermissions((prev) => {
    const newPermissions = [
      ...new Set([...prev, ...MULTIPLE_INSERTION.ROLE_CREATE]),
    ];
    return newPermissions;
  });
};
