import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: {},
  status: "idle",
  tenantList: [],
  inviteModal: {
    visible: false,
    tenant: {},
  },
  deleteModal: {
    visible: false,
    tenant: {},
  },
  showToastMessage: {
    visible: false,
    data: {},
  },
};

export const tenantsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    updateTenants: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { updateTenants } = tenantsSlice.actions;

export default tenantsSlice.reducer;
