import React from "react";

const ItemBody5 = ({ notification }) => {
  return (
    <p className="font-poppins text-xs font-light">
      <span className="font-medium text-primary ">
        {notification?.metaData?.hubName}{" "}
      </span>
      has <span className="font-medium text-primary">accepted </span>
      your request of {notification?.metaData?.batteries} batteries. Please
      reach the hub station to check-in the batteries.
    </p>
  );
};

export default ItemBody5;
