/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import CustomLayout from "../../../Core/Layout/CustomLayout";
import Button from "../../../Core/Components/CustomButton";
import { CustomInputWithController } from "../../../Core/Components/CustomInput";
import { PopoverDropdown } from "../../../Core/Components/CustomDropdown";
import { LOCATION_TYPE } from "../Data";
import getLocationType from "../../../Utils/getLocationType";
import {
  addLocationV3,
  editLocationV3,
} from "../../../Redux/APIs/locationsAPI";

const locationOptions = [
  {
    id: 1,
    value: LOCATION_TYPE.COLLECTION,
    customField: getLocationType(LOCATION_TYPE.COLLECTION),
  },
  {
    id: 2,
    value: LOCATION_TYPE.DELIVERY,
    customField: getLocationType(LOCATION_TYPE.DELIVERY),
  },
  {
    id: 3,
    value: LOCATION_TYPE.AGGREGATION,
    customField: getLocationType(LOCATION_TYPE.AGGREGATION),
  },
];

const LabelAndInput = ({
  label,
  control,
  formState,
  name,
  placeholder,
  rules,
  focus,
  setFocus,
}) => {
  return (
    <div className="">
      <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
        {label}
      </p>
      <CustomInputWithController
        intent="createUser"
        control={control}
        formState={formState}
        focus={focus}
        setFocus={(e) => setFocus(e)}
        name={name}
        placeholder={placeholder}
        rules={rules}
      />
    </div>
  );
};

const AddLocation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const { currentLocation, allLocations } = useSelector(
    (state) => state.locations,
  );

  // get all the hubs
  const hubStations = allLocations
    .filter((item) => item.type === LOCATION_TYPE.HUB_STATION)
    .map((location) => ({
      id: location.id,
      value: location.name,
    }));

  const { handleSubmit, control, formState, setValue, clearErrors, reset } =
    useForm({
      defaultValues: {
        name: id ? currentLocation.name : "",
        address: id ? currentLocation.address : "",
        lat: id ? currentLocation.lat : null,
        long: id ? currentLocation.long : null,
        activity: id ? currentLocation.type : "",
        hubId: id ? currentLocation?.hubId : null,
        hubName: id ? currentLocation?.hubName : "",
      },
    });

  const [focus, setFocus] = useState({
    name: false,
    address: false,
    lat: false,
    long: false,
  });

  const rules = {
    required: "Required.",
  };

  const ruleForLat = {
    required: "Required.",
    validate: {
      isNumber: (value) => {
        return (value <= 90 && value >= -90) || "Latitude can only be a number";
      },
    },
  };

  const ruleForLong = {
    required: "Required.",
    validate: {
      isNumber: (value) => {
        return (
          (value <= 180 && value >= -180) || "Longtitude can only be a number"
        );
      },
    },
  };

  const activityError = formState?.errors?.activity?.message;
  const hubNameError = formState?.errors?.hubName?.message;

  const onCancel = () => navigate("/locations");

  const onAdd = async (e) => {
    const payload = e;

    if (id) {
      payload.locationId = id;
      await dispatch(editLocationV3(payload));
    } else {
      await dispatch(addLocationV3(payload));
    }

    reset();
    navigate("/locations");
  };

  return (
    <CustomLayout pageName="Locations" hideFooter>
      <div className="px-6 pt-6 bg-background-white">
        <div className="flex items-center justify-between pb-6">
          <p className="font-poppins text-base font-medium capitalize">
            {id ? "Edit Location" : "Add a new location"}
          </p>

          <div className="flex items-center gap-x-3">
            <Button
              size="filter"
              width="generateUser"
              colors="grey"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              size="filter"
              width="generateUser"
              type="submit"
              onClick={handleSubmit(onAdd)}
            >
              {id ? "Edit" : "Add"} Location
            </Button>
          </div>
        </div>

        <div className="w-full bg-light-grey h-[1px]" />
      </div>

      <div className="h-[calc(100vh-88px-72px)] bg-background-white p-6 flex flex-col gap-y-6 overflow-y-scroll">
        <LabelAndInput
          label="Name"
          control={control}
          formState={formState}
          name="name"
          placeholder="Enter Name"
          rules={rules}
          focus={focus.name}
          setFocus={(state) => setFocus({ ...focus, name: state })}
        />

        <LabelAndInput
          label="Address"
          control={control}
          formState={formState}
          name="address"
          placeholder="Enter Address"
          rules={rules}
          focus={focus.address}
          setFocus={(state) => setFocus({ ...focus, address: state })}
        />

        <LabelAndInput
          label="Latitude"
          control={control}
          formState={formState}
          name="lat"
          placeholder="Enter Latitude"
          rules={ruleForLat}
          focus={focus.lat}
          setFocus={(state) => setFocus({ ...focus, lat: state })}
        />

        <LabelAndInput
          label="Longitude"
          control={control}
          formState={formState}
          name="long"
          placeholder="Enter Longitude"
          rules={ruleForLong}
          focus={focus.long}
          setFocus={(state) => setFocus({ ...focus, long: state })}
        />

        <div className="">
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Hub Station
          </p>

          <Controller
            name="hubName"
            control={control}
            rules={rules}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                enableSearch
                searchPlaceholder="Search Hub Station"
                placeholder="Assign Hub Station"
                className="max-h-[300px] overflow-y-scroll"
                formState={formState}
                options={hubStations}
                onSelect={(data) => {
                  setValue("hubName", data.value);
                  setValue("hubId", data.id);
                  clearErrors("hubName");
                }}
              />
            )}
          />

          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 ${
              hubNameError ? "text-alert-red visible mt-2" : "invisible"
            }`}
          >
            {hubNameError}
          </p>
        </div>

        <div>
          <p className="font-aileron text-xs font-normal text-dark-gray capitalize mb-1.5">
            Activity
          </p>
          <Controller
            name="activity"
            control={control}
            rules={rules}
            render={({ field }) => (
              <PopoverDropdown
                {...field}
                placeholder="Select Activity"
                formState={formState}
                options={locationOptions}
                customValueRender={() =>
                  field.value ? (
                    getLocationType(parseInt(field.value, 10))
                  ) : (
                    <p>Select Activity</p>
                  )
                }
                onSelect={(data) => {
                  setValue("activity", data.value);
                  clearErrors("activity");
                }}
              />
            )}
          />
          <p
            className={`font-poppins text-xs font-light capitalize leading-4.5 ${
              activityError ? "text-alert-red visible mt-2" : "invisible"
            }`}
          >
            {activityError}
          </p>
        </div>
      </div>
    </CustomLayout>
  );
};

export default AddLocation;
