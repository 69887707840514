/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "antd";
import CustomInput from "../../../Core/Components/CustomInput";
import UpdateTheme from "./UpdateTheme";
import UploadLogo from "./UploadLogo";
import Button from "../../../Core/Components/CustomButton";
import getThemeColor from "../../../Utils/getThemeColor";
import colorThemes from "../Data/generalData";
import Label from "../Label";
import {
  getMetaData,
  getUserByEmail,
  updateMetaData,
  updateUser,
} from "../../../Redux/APIs/systemUsersAPI";
import { updateLoggedInUser } from "../../../Redux/Slices/loggedInUser.slice";
import ToastMessageModal from "../ToastMessageModal";
import ResetButton from "./ResetButton";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import useDataForGeneralSettings from "./useDataForGeneralSettings";

const GeneralSettings = () => {
  const dispatch = useDispatch();

  const { userDetails, theme, toastMessageModal } = useSelector(
    (state) => state.loggedInUser,
  );

  // check if user has edit permission (for account settings)
  const hasEditPermission = usePermission([
    PERMISSIONS.SETTINGS_GENERAL_VIEW_AND_EDIT,
  ]);

  // check if user has personalisation permission (for logo and theme)
  const hasPersonalisationPermission = usePermission([
    PERMISSIONS.SETTINGS_PERSONALISATION,
  ]);

  const [selectedTheme, setSelectedTheme] = useState(getThemeColor(theme));
  const [uploadLogo, setUploadLogo] = useState(null);
  const [name, setName] = useState({
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    email: userDetails.email,
  });

  const dataForGeneralSettings = useDataForGeneralSettings(name);

  const clearToastMessage = () => {
    setTimeout(() => {
      dispatch(
        updateLoggedInUser({
          key: "toastMessageModal",
          value: { ...toastMessageModal, visible: false },
        }),
      );
    }, 10000);
  };

  const handleSaveChange = async () => {
    // if first name or last name has been changed
    if (
      userDetails.firstName !== name.firstName ||
      userDetails.lastName !== name.lastName
    ) {
      await dispatch(updateUser(name));

      await dispatch(getUserByEmail({ email: userDetails.email }));

      dispatch(
        updateLoggedInUser({
          key: "toastMessageModal",
          value: {
            visible: true,
            title: "Account Settings Updated",
            description: "Your account was updated successfully.",
          },
        }),
      );

      clearToastMessage();
    }

    if (hasPersonalisationPermission) {
      // if theme has been changed and new logo has been uploaded
      if (theme !== colorThemes[selectedTheme] || uploadLogo !== null) {
        const payload = {
          theme: colorThemes[selectedTheme],
        };

        if (uploadLogo !== null) {
          payload.companyLogo = uploadLogo;
        }

        await dispatch(updateMetaData(payload));

        await dispatch(getMetaData());

        setUploadLogo(null);

        dispatch(
          updateLoggedInUser({
            key: "toastMessageModal",
            value: {
              visible: true,
              title: "Account Settings Updated",
              description: "Your account was updated successfully.",
            },
          }),
        );

        clearToastMessage();
      }
    }
  };

  const handleNameChange = (e, field) => {
    setName({ ...name, [field]: e.target.value });
  };

  const handleCancel = () => {
    setName({
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
    });

    setSelectedTheme(getThemeColor(theme));

    setUploadLogo(null);
  };

  useEffect(() => {
    dispatch(getMetaData());
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, []);

  return (
    <div className="w-full h-full p-6">
      <div className="flex items-start justify-between">
        <div>
          <p className="text-xs font-medium text-black font-poppins">
            Account settings
          </p>

          <p className="mt-2 text-xs font-normal text-dark-gray font-aileron">
            Manage your account settings
          </p>
        </div>
        <div className="flex flex-col gap-y-6 w-[50%]">
          {dataForGeneralSettings.map((item) => (
            <div key={item.id}>
              <Label text={item.label} />
              <CustomInput
                intent={item.intent}
                border="primary"
                text="generalSettings"
                defaultValue={item.defaultValue}
                value={item?.value}
                placeholder={item.placeholder}
                onChange={(e) => handleNameChange(e, item.field)}
                disabled={item.disabled}
              />
            </div>
          ))}
        </div>
      </div>

      {hasPersonalisationPermission && <Divider />}

      {hasPersonalisationPermission && (
        <div className="flex items-start justify-between pb-6 mt-6 border-b">
          <div>
            <div className="flex items-center justify-between">
              <p className="text-xs font-medium text-black capitalize font-poppins">
                personalise your experience
              </p>
              <ResetButton setSelectedTheme={setSelectedTheme} />
            </div>
            <p className="mt-2 text-xs font-normal text-dark-gray font-aileron">
              Add your company logo and change theme
            </p>
          </div>
          <div className="flex flex-col gap-y-6 w-[50%]">
            <UploadLogo uploadLogo={uploadLogo} setUploadLogo={setUploadLogo} />
            <UpdateTheme
              selectedTheme={selectedTheme}
              setSelectedTheme={setSelectedTheme}
            />
          </div>
        </div>
      )}

      {(hasEditPermission || hasPersonalisationPermission) && (
        <div className="flex justify-end mt-6 gap-x-4">
          <Button
            size="primary"
            width="dynamic"
            colors="grey"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            size="primary"
            width="save"
            type="submit"
            onClick={handleSaveChange}
          >
            Save Changes
          </Button>
        </div>
      )}

      <ToastMessageModal
        visible={toastMessageModal.visible}
        onCancel={() =>
          dispatch(
            updateLoggedInUser({
              key: "toastMessageModal",
              value: { ...toastMessageModal, visible: false },
            }),
          )
        }
      />
    </div>
  );
};

export default GeneralSettings;
