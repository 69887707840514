import React from "react";
import { DeleteAction } from "../Users/EditAndDeleteDropDown";
import AssignRoleDropdown from "../Users/AssignRoleDropdown";
import AssignHubStationDropdown from "../Users/AssignHubStationDropdown";

const systemUserColumns = [
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        First Name
      </div>
    ),
    width: "25%",
    dataIndex: "firstName",
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
    render: (text) => (
      <div className="text-xs font-semibold text-black font-aileron py-2">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Last Name
      </div>
    ),
    width: "25%",
    dataIndex: "lastName",
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray capitalize font-aileron">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Email
      </div>
    ),
    width: "30%",
    dataIndex: "email",
    sorter: (a, b) => a.email.localeCompare(b.email),
    render: (text) => (
      <div className="text-xs font-semibold text-dark-gray font-aileron">
        {text}
      </div>
    ),
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">Role</div>
    ),
    width: "25%",
    dataIndex: "roleName",
    sorter: (a, b) => a.roleName.localeCompare(b.roleName),
    render: (text, record) => <AssignRoleDropdown detail={record} />,
  },
  {
    title: () => (
      <div className="font-light font-aileron text-10 text-dark-gray">
        Hub Station
      </div>
    ),
    width: "25%",
    dataIndex: "roleName",
    sorter: (a, b) => a.roleName.localeCompare(b.roleName),
    render: (text, record) => <AssignHubStationDropdown detail={record} />,
  },
  {
    dataIndex: "id",
    width: "100px",
    render: (text, record) => <DeleteAction user={record} />,
  },
];

export default systemUserColumns;
