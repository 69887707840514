import React from "react";
import TickIcon from "../../../Common/Svgs/TickIcon";
import CloseIcon from "../../../Common/Svgs/CloseIcon";

const ItemBody4 = ({ notification }) => {
  return (
    <div>
      <p className="font-poppins text-xs font-light text-black">
        <span className="font-medium text-primary">
          {notification?.metaData?.hubName}{" "}
        </span>
        is requesting {notification?.metaData?.batteries} batteries. <br />
        Do you want to accept?
      </p>

      <div className="mt-1 w-full flex items-center gap-x-2">
        <button
          type="button"
          className="flex flex-1 items-center gap-x-2 bg-primary hover:bg-primary-50 py-2 px-4 rounded-md"
        >
          <TickIcon size={16} className="text-white" />

          <p className="font-poppins text-xs font-medium text-background-white">
            Accept
          </p>
        </button>

        <button
          type="button"
          className="flex flex-1 items-center gap-x-2 bg-alert-10 hover:bg-light-alert-red py-2 px-4 rounded-md"
        >
          <CloseIcon className="w-4 h-4 stroke-alert-red" />

          <p className="font-poppins text-xs font-medium text-alert-red">
            Reject
          </p>
        </button>
      </div>
    </div>
  );
};

export default ItemBody4;
