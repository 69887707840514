/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLayout from "../../Core/Layout/CustomLayout";
import DriversList from "./phase3/DriversList";
import PartnersList from "./phase3/PartnersList";
import { updateUsers } from "../../Redux/Slices/users.slice";
import ToastMessageModal from "./phase3/ToastMessageModal";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";

function UsersList() {
  const dispatch = useDispatch();

  const { selectedTab, showToastMessage } = useSelector((state) => state.users);

  // check permission for driver module
  const hasDriverPermission = usePermission([
    PERMISSIONS.DRIVER_VIEW,
    PERMISSIONS.DRIVER_VIEW_AND_EDIT,
  ]);

  const handleTabSelection = useCallback(async () => {
    // if the driver permission exists, then select driver tab, else select farmer tab
    if (hasDriverPermission) {
      dispatch(
        updateUsers({
          key: "selectedTab",
          value: 1,
        }),
      );
    } else {
      dispatch(
        updateUsers({
          key: "selectedTab",
          value: 2,
        }),
      );
    }
  }, [hasDriverPermission]);

  // update selected tab to drivers whenever leaving the page
  useEffect(() => {
    return () => {
      dispatch(
        updateUsers({
          key: "selectedTab",
          value: 1,
        }),
      );
    };
  }, [dispatch]);

  // clear the toast message
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateUsers({
          key: "showToastMessage",
          value: {
            visible: false,
            data: {},
          },
        }),
      );
    }, 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, showToastMessage.visible]);

  // update the tab selection based on the permission
  useEffect(() => {
    handleTabSelection();
  }, [handleTabSelection]);

  return (
    <CustomLayout pageName="Users">
      {selectedTab === 1 ? <DriversList /> : <PartnersList />}

      <ToastMessageModal
        visible={showToastMessage.visible}
        onCancel={() =>
          dispatch(
            updateUsers({
              key: "showToastMessage",
              value: {
                visible: false,
                data: {},
              },
            }),
          )
        }
        title={showToastMessage?.data?.title}
        description={showToastMessage?.data?.description}
      />
    </CustomLayout>
  );
}
export default UsersList;
