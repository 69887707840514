/* eslint-disable import/prefer-default-export */
export const PERMISSIONS = {
  HOME_VIEW: 1,
  TRIKE_VIEW: 2,
  BATTERY_VIEW: 3,
  TRIP_VIEW: 4,
  DRIVER_VIEW: 5,
  PARTNER_VIEW: 6,
  LOCATIONS_VIEW: 7,
  INVENTORY_VIEW: 8,
  SERVICE_VIEW: 9,
  QRCODE_VIEW: 10,
  TRIKE_VIEW_AND_EDIT: 11,
  TRIKE_NO_ACCESS: 12,
  TRIKE_ADD: 13,
  TRIKE_EDIT: 14,
  TRIKE_DELETE: 15,
  TRIKE_DISPATCH_AND_MOVE: 16,
  TRACKER_SWITCH: 17,
  TRIKE_MOBILISE: 18,
  TRIKE_CHECKLIST_ADD: 19,
  TRIKE_CHECKLIST_EDIT: 20,
  TRIKE_CHECKLIST_DELETE: 21,
  TRIKE_GEOFENCE_ADD: 22,
  TRIKE_GEOFENCE_EDIT: 23,
  TRIKE_GEOFENCE_DELETE: 24,
  TRIKE_ISSUE_ADD: 25,
  TRIKE_ISSUE_VIEW: 26,
  TRIKE_ADD_TO_SERVICE: 27,
  TRIKE_NO_SERVICE: 28,
  TRIKE_VIEW_QR: 29,
  TRIKE_NO_QR: 30,
  BATTERY_VIEW_AND_EDIT: 31,
  BATTERY_NO_ACCESS: 32,
  BATTERY_ADD: 33,
  BATTERY_EDIT: 34,
  BATTERY_DELETE: 35,
  BATTERY_ADD_TO_SERVICE: 36,
  BATTERY_NO_SERVICE: 37,
  BATTERY_VIEW_QR: 38,
  BATTERY_NO_QR: 39,
  TRIP_VIEW_AND_EDIT: 40,
  TRIP_NO_ACCESS: 41,
  TRIP_CREATE: 42,
  TRIP_EDIT: 43,
  TRIP_DELETE: 44,
  TRIP_MANUAL_END: 45,
  TRIP_STOP_DETAILS: 46,
  TRIP_NO_STOP_DETAILS: 47,
  DRIVER_VIEW_AND_EDIT: 48,
  DRIVER_NO_ACCESS: 49,
  DRIVER_ADD: 50,
  DRIVER_EDIT: 51,
  DRIVER_DELETE: 52,
  DRIVER_SUSPEND: 53,
  PARTNER_VIEW_AND_EDIT: 54,
  PARTNER_NO_ACCESS: 55,
  PARTNER_ADD: 56,
  PARTNER_EDIT: 57,
  PARTNER_DELETE: 58,
  PARTNER_VIEW_QR: 59,
  PARTNER_NO_QR: 60,
  PARTNER_EXPORT: 61,
  PARTNER_NO_EXPORT: 62,
  LOCATION_VIEW_AND_EDIT: 63,
  LOCATION_NO_ACCESS: 64,
  LOCATION_ADD: 65,
  LOCATION_EDIT: 66,
  LOCATION_DELETE: 67,
  INVENTORY_NO_ACCESS: 68,
  SERVICE_VIEW_AND_EDIT: 69,
  SERVICE_NO_ACCESS: 70,
  SERVICE_EDIT: 71,
  SERVICE_COMPLETE: 72,
  SETTINGS_GENERAL_VIEW_AND_EDIT: 73,
  SETTINGS_GENERAL_VIEW: 74,
  SETTINGS_PERSONALISATION: 75,
  SETTINGS_NO_PERSONALISATION: 76,
  SETTINGS_SYSTEM_USER_VIEW_AND_EDIT: 77,
  SETTINGS_SYSTEM_USER_VIEW: 78,
  SETTINGS_SYSTEM_USER_NO_ACCESS: 79,
  SETTINGS_SYSTEM_USER_CREATE: 80,
  SETTINGS_SYSTEM_USER_EDIT: 81,
  SETTINGS_SYSTEM_USER_DELETE: 82,
  SETTINGS_ROLE_CREATE: 83,
  SETTINGS_ROLE_EDIT: 84,
  SETTINGS_ROLE_DELETE: 85,
};

export const SYSTEM_USER_ROLE = 1;
