/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { getFarmersV3 } from "../../../Redux/APIs/usersAPI";
import { farmersColumns } from "../Data/UsersListData";
import CustomTable from "../../../Core/Components/CustomTable";
import UserIcon from "../../../Common/Svgs/UserIcon";
import usePermission from "../../../Core/hooks/usePermission";
import { PERMISSIONS } from "../../Login/constants";
import { getUserByEmail } from "../../../Redux/APIs/systemUsersAPI";
import useDebounce from "../../../Core/hooks/useDebounce";
import PartnerHeader from "./PartnerHeader";
import DeleteFarmerModal from "./DeleteFarmerModal";
import { updateUsers } from "../../../Redux/Slices/users.slice";
import ExportFarmerModal from "./ExportFarmerModal";
import QRCodeModal from "../../../Core/Components/Modal/QRCodeModal";
import { updateQrCode } from "../../../Redux/Slices/qrcode.slice";

const LOCAL_DEFAULT_LIMIT = 10;

const PartnersList = () => {
  const dispatch = useDispatch();

  const { farmersListV3, deleteFarmerModal, exportFarmerModal } = useSelector(
    (state) => state.users,
  );

  const { viewModal } = useSelector((state) => state.qrCode);

  const { userDetails } = useSelector((state) => state.loggedInUser);

  // check if any of the three dots permissions exist (cursor pointer scenario)
  const hasThreeDotsFarmersPermission = usePermission([
    PERMISSIONS.PARTNER_EDIT,
    PERMISSIONS.PARTNER_VIEW_QR,
    PERMISSIONS.PARTNER_DELETE,
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    hub: [],
    farmerDateRangeStart: "",
    farmerDateRangeEnd: "",
    farmerSelected: [],
  });

  const debounceSearch = useDebounce(search, 500);

  // fetch farmers (pagination based) along with filters
  useEffect(() => {
    const filterObj = {
      offset: ((currentPage || 1) - 1) * LOCAL_DEFAULT_LIMIT,
      limit: LOCAL_DEFAULT_LIMIT,
    };

    if (debounceSearch) filterObj.searchQuery = debounceSearch;

    if (filters.hub.length) filterObj.hub = filters.hub;

    if (filters.farmerSelected.length) {
      filterObj.farmerSelected = filters.farmerSelected;
    }

    if (
      filters.farmerDateRangeStart !== "" &&
      filters.farmerDateRangeEnd !== ""
    ) {
      filterObj.dateRangeStart = filters.farmerDateRangeStart;
      filterObj.dateRangeEnd = filters.farmerDateRangeEnd;
    }

    dispatch(getFarmersV3(filterObj));
  }, [dispatch, currentPage, filters, debounceSearch]);

  // fetch user details (to get permissions on every refresh)
  useEffect(() => {
    dispatch(getUserByEmail({ email: userDetails.email }));
  }, [dispatch]);

  return (
    <div>
      <PartnerHeader
        search={search}
        setSearch={setSearch}
        filters={filters}
        setFilters={setFilters}
        setCurrentPage={setCurrentPage}
      />

      <CustomTable
        scrollX={0}
        scrollY="446px"
        columns={farmersColumns}
        data={farmersListV3.result}
        emptyState={
          <UserIcon className="w-4 h-4 fill-none stroke-dark-grey-text" />
        }
        rowClassName={
          hasThreeDotsFarmersPermission ? "group cursor-pointer" : "group"
        }
        pagination={{
          pageSize: LOCAL_DEFAULT_LIMIT,
          total: farmersListV3.totalRecords,
          showSizeChanger: false,
          onChange: (page) => {
            setCurrentPage(page);
          },
          current: currentPage,
          itemRender: (current, type, originalElement) => {
            const lastPage = farmersListV3.totalRecords / current;

            if (type === "prev" && current === 0) {
              return (
                <button
                  type="button"
                  className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "prev" && current > 0) {
              return (
                <button
                  type="button"
                  className="-rotate-180 rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "next" && lastPage <= 10) {
              return (
                <button
                  type="button"
                  className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                  style={{ pointerEvents: "none" }}
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            if (type === "next") {
              return (
                <button
                  type="button"
                  className="rounded-md h-[32px] min-w-[32px] bg-background flex items-center justify-center p-2"
                >
                  <ChevronIcon className="stroke-dark-gray" />
                </button>
              );
            }

            return originalElement;
          },
        }}
      />

      <DeleteFarmerModal
        visible={deleteFarmerModal?.visible}
        onCancel={() =>
          dispatch(
            updateUsers({
              key: "deleteFarmerModal",
              value: {
                visible: false,
                id: 0,
              },
            }),
          )
        }
        farmersCurrentPage={currentPage}
      />

      <ExportFarmerModal
        visible={exportFarmerModal}
        onCancel={() =>
          dispatch(
            updateUsers({
              key: "exportFarmerModal",
              value: false,
            }),
          )
        }
      />

      <QRCodeModal
        visible={viewModal?.visible}
        type={viewModal?.data?.assetType}
        onCancel={() =>
          dispatch(
            updateQrCode({
              key: "viewModal",
              value: { visible: false, data: {} },
            }),
          )
        }
      />
    </div>
  );
};

export default PartnersList;
