import React from "react";
import EditIcon from "../../Common/Svgs/EditIcon";
import usePermission from "../../Core/hooks/usePermission";
import { PERMISSIONS } from "../Login/constants";
import TrashIcon from "../../Common/Svgs/TrashIcon";

const useChargerThreeDotsOptions = () => {
  const threeDotsOptions = [
    {
      id: 1,
      value: "Edit Details",
      icon: <EditIcon className="text-dark-gray group-hover:text-black" />,
      hasPermission: usePermission([PERMISSIONS.BATTERY_EDIT]),
    },
    {
      id: 2,
      value: "Delete Charger",
      icon: <TrashIcon className="text-alert-red" />,
      hasPermission: usePermission([PERMISSIONS.BATTERY_DELETE]),
    },
  ];

  return threeDotsOptions;
};

export default useChargerThreeDotsOptions;
