import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import EnergyResourcesIcon from "../../../Common/Svgs/EnergyResourcesIcon";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { useEnergyResourcesItem } from "./useItems";
import { SUBMENU } from "../data/contants";

const EnergyResourcesItem = ({
  sidebarActive,
  pageName,
  subMenu,
  setSubMenu,
}) => {
  const navigate = useNavigate();

  // const [subMenu, setSubMenu] = useState(false);

  // sub menu items
  const items = useEnergyResourcesItem().filter((item) => item.hasPermission);

  const activeItem = items.findIndex(
    (i) => i.label.toLowerCase() === pageName.toLowerCase(),
  );

  const handleSubMenu = () => {
    if (subMenu === SUBMENU.ENERGY_RESOURCES) {
      setSubMenu(SUBMENU.DEFAULT);
    } else {
      setSubMenu(SUBMENU.ENERGY_RESOURCES);
    }
  };

  const getContainerStyle = () => {
    if (sidebarActive) return "bg-transparent-white";
    return "";
  };

  const getHeaderMenuStyle = () => {
    if (activeItem > -1 || subMenu === SUBMENU.ENERGY_RESOURCES) {
      return "text-sidebar-active stroke-sidebar-active";
    }

    return "text-sidebar-base stroke-sidebar-base";
  };

  const getSubMenuStyle = (item) => {
    if (pageName.toLowerCase() === item.toLowerCase()) return "text-primary";
    return "text-sidebar-base group-hover:text-sidebar-active";
  };

  // close the submenu if the sidebar is closed
  useEffect(() => {
    if (!sidebarActive) {
      setSubMenu(SUBMENU.DEFAULT);
    }
  }, [sidebarActive, setSubMenu]);

  return (
    <div>
      <div
        role="button"
        className={`flex items-center mt-5 py-2 px-6 h-9 cursor-pointer w-full group ${getContainerStyle()}`}
        // onClick={() => setSubMenu((prev) => !prev)}
        onClick={handleSubMenu}
      >
        <motion.div
          // layout={subMenu === SUBMENU.DEFAULT}
          className="w-[22px] h-5"
        >
          <EnergyResourcesIcon
            className={`group-hover:text-sidebar-active shrink-0 ${getHeaderMenuStyle()}`}
          />
        </motion.div>

        {sidebarActive && (
          <motion.p
            // layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className={`w-full ml-2 flex-1 font-aileron font-semibold text-xs group-hover:text-sidebar-active ${getHeaderMenuStyle()}`}
          >
            Energy Resources
          </motion.p>
        )}

        {sidebarActive && (
          <motion.div
            // layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="w-2.5 h-2.5"
          >
            <ChevronIcon
              strokeWidth="1.5"
              className={`w-2.5 h-2.5 group-hover:stroke-sidebar-active transform transition-all ${getHeaderMenuStyle()}
              ${subMenu ? "-rotate-90" : "rotate-90"}
            `}
            />
          </motion.div>
        )}
      </div>

      {sidebarActive &&
        subMenu === SUBMENU.ENERGY_RESOURCES &&
        items.map((i) => {
          const selected = pageName.toLowerCase() === i.label.toLowerCase();

          return (
            <div
              key={i.label}
              className={`flex items-center gap-x-2 py-3 px-[30px] cursor-pointer group ${
                selected && "bg-sidebar-active"
              }`}
              onClick={() => navigate(i.path)}
            >
              <span className={`w-4 h-4 ${getSubMenuStyle(i.label)}`}>
                {i.icon}
              </span>
              <p
                className={`font-aileron text-xs font-normal capitalize ${getSubMenuStyle(
                  i.label,
                )}`}
              >
                {i.label}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default EnergyResourcesItem;
