/* eslint-disable no-console */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../Core/Components/Modal/CustomModal";
import CloseIcon from "../../../Common/Svgs/CloseIcon";
import Button from "../../../Core/Components/CustomButton";
import { updateTenants } from "../../../Redux/Slices/tenants.slice";

const InviteModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { inviteModal } = useSelector((state) => state.tenants);

  const handleInvite = () => {
    dispatch(
      updateTenants({
        key: "showToastMessage",
        value: {
          visible: true,
          data: {
            title: "Tenant Created & invite sent",
            description: `${inviteModal?.tenant?.organisation} was created and invited successfully.`,
          },
        },
      }),
    );

    navigate("/tenant-sign-up");

    onCancel();
  };

  return (
    <CustomModal visible={visible} onCancel={onCancel} width="536px">
      <div
        className="p-4"
        style={{
          boxShadow:
            "12px 12px 16px -4px rgba(16, 24, 40, 0.08), -4px -4px 6px -2px rgba(16, 24, 40, 0.03)",
        }}
      >
        <div className="flex items-center justify-between">
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-primary">
            <img
              src="/assets/invite-tenant.png"
              className="w-6 h-6"
              alt="invite tenant"
            />
          </div>

          <button type="button" className="mr-2" onClick={onCancel}>
            <CloseIcon className="stroke-dark-gray w-6 h-6" />
          </button>
        </div>

        <div className="mt-5 px-2 pb-2">
          <h1 className="font-poppins text-base font-medium text-primary">
            Create & Invite?
          </h1>

          <p className="mt-2 font-aileron text-xs font-normal">
            Please check the organization name and email as this information
            cannot be changed later.
          </p>

          <div className="mt-11 flex justify-end gap-x-3">
            <Button colors="grey" size="primary" onClick={onCancel}>
              Cancel
            </Button>
            <Button colors="primary" size="primary" onClick={handleInvite}>
              Confirm & Invite
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default InviteModal;
