import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ChevronIcon from "../../../Common/Svgs/ChevronIcon";
import { useAssetsItem } from "./useItems";
import AssetsIcon from "../../../Common/Svgs/AssetsIcon";
import getThemeColor from "../../../Utils/getThemeColor";
import { SUBMENU } from "../data/contants";

const AssetsItem = ({ sidebarActive, pageName, subMenu, setSubMenu }) => {
  const navigate = useNavigate();

  const { theme } = useSelector((state) => state.loggedInUser);

  // const [subMenu, setSubMenu] = useState(false);

  // sub menu items
  const items = useAssetsItem().filter((item) => item.hasPermission);

  const activeItem = items.findIndex(
    (i) => i.label.toLowerCase() === pageName.toLowerCase(),
  );

  const handleSubMenu = () => {
    if (subMenu === SUBMENU.ASSETS) {
      setSubMenu(SUBMENU.DEFAULT);
    } else {
      setSubMenu(SUBMENU.ASSETS);
    }
  };

  const getContainerStyle = () => {
    if (sidebarActive) return "bg-transparent-white";
    return "";
  };

  const getHeaderMenuStyle = () => {
    if (activeItem > -1 || subMenu === SUBMENU.ASSETS) {
      return "text-sidebar-active stroke-sidebar-active";
    }

    return "text-sidebar-base stroke-sidebar-base";
  };

  const getHeaderIconStyle = () => {
    // if the current page is part of the submenu the fill the header icon
    if (activeItem > -1) {
      return theme === "green-theme" ? "#fad152" : "#fff";
    }

    // if the sidebar is closed then fill color will be the theme color
    if (!sidebarActive) {
      return getThemeColor(theme);
    }

    // if none of the conditions are true then return the bgColor of the Header item (transparent-white)
    return "#387275";
  };

  const getSubMenuStyle = (item) => {
    if (pageName.toLowerCase() === item.toLowerCase()) return "text-primary";
    return "text-sidebar-base group-hover:text-sidebar-active";
  };

  // close the submenu if the sidebar is closed
  useEffect(() => {
    if (!sidebarActive) {
      setSubMenu(SUBMENU.DEFAULT);
    }
  }, [sidebarActive, setSubMenu]);

  return (
    <div>
      <div
        role="button"
        className={`flex items-center mt-5 py-2 px-6 h-9 cursor-pointer w-full group ${getContainerStyle()}`}
        // onClick={() => setSubMenu((prev) => !prev)}
        onClick={handleSubMenu}
      >
        <motion.div
          // layout={subMenu === SUBMENU.DEFAULT}
          className="w-[22px] h-5"
        >
          <AssetsIcon
            className={`group-hover:text-sidebar-active shrink-0 ${getHeaderMenuStyle()}`}
            fillColor={getHeaderIconStyle()}
          />
        </motion.div>

        {sidebarActive && (
          <motion.p
            // layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className={`w-full ml-2 flex-1 font-aileron font-semibold text-xs group-hover:text-sidebar-active ${getHeaderMenuStyle()}`}
          >
            Assets
          </motion.p>
        )}

        {sidebarActive && (
          <motion.div
            // layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="w-2.5 h-2.5"
          >
            <ChevronIcon
              strokeWidth="1.5"
              className={`w-2.5 h-2.5 group-hover:stroke-sidebar-active transform transition-all ${getHeaderMenuStyle()}
              ${subMenu ? "-rotate-90" : "rotate-90"}
            `}
            />
          </motion.div>
        )}
      </div>

      {sidebarActive &&
        subMenu === SUBMENU.ASSETS &&
        items.map((i) => {
          const selected = pageName.toLowerCase() === i.label.toLowerCase();

          return (
            <div
              key={i.label}
              className={`flex items-center gap-x-2 py-3 px-[30px] cursor-pointer group ${
                selected && "bg-sidebar-active"
              }`}
              onClick={() => navigate(i.path)}
            >
              <span className={`w-4 h-4 ${getSubMenuStyle(i.label)}`}>
                {i.icon}
              </span>
              <p
                className={`font-aileron text-xs font-normal capitalize ${getSubMenuStyle(
                  i.label,
                )}`}
              >
                {i.label}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default AssetsItem;
